import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { ButtonSecondary } from 'components/Button'
import { PageWrapper } from 'components/swap/styleds'
import { TitleDesc, TitleRow } from 'nft/components/profile/list/shared'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

import ContentWrapper from './ContentWrapper'
import { LiquidityTable } from './LiquidityTable'

const StyledMore = styled.span`
  margin-left: 2px;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.moreBorder};
`

export default function Pools() {
  return (
    <PageWrapper data-testid="liquidity-page">
      <TitleRow>
        <ThemedText.LargeHeader>
          <Trans>Pools</Trans>
        </ThemedText.LargeHeader>
      </TitleRow>
      <TitleDesc>
        <ThemedText.HeaderDesc lineHeight="16px">
          <Trans>
            Pair your tokens to support liquidity in both Spot and Perpetual trading. Utilize RogueX's Auto-Liquidity
            Management (ALM) feature to add liquidity and earn $ROX rewards.
            <StyledMore className="active-gradient-text">Learn More</StyledMore>
          </Trans>
        </ThemedText.HeaderDesc>
        {/* <Box mt="16px">
          <LearnMore />
        </Box> */}
      </TitleDesc>
      <Box mt="20px" width="fit-content">
        <ButtonSecondary height="40px" as={Link} to="/add/ETH">
          Create New Pool
        </ButtonSecondary>
      </Box>
      <ContentWrapper />
      <LiquidityTable />
    </PageWrapper>
  )
}
