import { Box } from '@mui/material'
import styled from 'styled-components/macro'
import { formatAmount } from 'utils/formatAmout'

import { teamGoalsArr } from './mis_team'

const TooltipContentBox = styled(Box)`
 width: 100%;
  max-width: 100%;
  overflow-x: auto;
  .rankHead {
    padding: 6px 12px;
    color: ${({ theme }) => theme.textSecondary};
    font-size: 12px;
    font-weight: 700;
    border-bottom: 1px solid ${({ theme }) => theme.sectionBorder};
  }
  .rankListTips {
    padding: 0 12px;
    .rankItem {
      padding: 8px 0;
      font-size: 14px;
      color: ${({ theme }) => theme.textPrimary};
      &:not(:last-child) {
        border-bottom: 1px dashed ${({ theme }) => theme.sectionBorder};
      }
​    }
  }
  `

export function TooltipContent() {
  return (
    <TooltipContentBox>
      <Box display="flex" alignItems="center" className="rankHead">
        <Box width="68px  " className="">
          Team Lv.
        </Box>
        <Box ml="8px" width="100px" className="">
          Points
        </Box>
        <Box ml="8px" width="80px " className="">
          Multiplier
        </Box>
        <Box ml="8px" width="90px" className="">
          Max Referral
        </Box>
      </Box>
      <div className="rankListTips">
        {teamGoalsArr?.map((item: any, index: number) => (
          <Box display="flex" alignItems="center" className="rankItem" key={index}>
            <Box width="68px  ">{item.lv}</Box>
            <Box ml="8px" width="100px" className="">
              {formatAmount(item.point, 0, true)}
            </Box>
            <Box ml="8px" width="80px " className="">
              {item.multiplier}x
            </Box>
            <Box ml="8px" width="90px" className="">
              {item.maxReferral}
            </Box>
          </Box>
        ))}
      </div>
    </TooltipContentBox>
  )
}
