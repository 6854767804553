import { ArrowChangeDown } from 'components/Icons/ArrowChangeDown'
import { ArrowChangeUp } from 'components/Icons/ArrowChangeUp'
import { HeaderCellWrapper, InfoIconContainer } from 'components/Tokens/TokenTable/TokenRow'
import { MouseoverTooltip } from 'components/Tooltip'
import { useAtomValue } from 'jotai/utils'
import { ReactNode } from 'react'
import { Info } from 'react-feather'
import { useTheme } from 'styled-components/macro'

import {
  LiquiditysortAscendingAtom,
  LiquiditySortMethod,
  LiquiditysortMethodAtom,
  useSetLiquiditySortMethod,
} from './state'

const HEADER_DESCRIPTIONS: Record<LiquiditySortMethod, ReactNode | undefined> = {
  [LiquiditySortMethod.POOLS]: undefined,
  [LiquiditySortMethod.TVL]: undefined,
  [LiquiditySortMethod.APR]: undefined,
  [LiquiditySortMethod.REWARDS]: undefined,
  [LiquiditySortMethod.MY_STAKED]: undefined,
  [LiquiditySortMethod.EARN]: undefined,
  [LiquiditySortMethod.BRIBES]: undefined,
}

export default function HeaderCell({
  category,
  justify,
}: {
  category: LiquiditySortMethod // TODO: change this to make it work for trans
  justify?: string
}) {
  const theme = useTheme()
  const sortAscending = useAtomValue(LiquiditysortAscendingAtom)
  const handleSortCategory = useSetLiquiditySortMethod(category)
  const sortMethod = useAtomValue(LiquiditysortMethodAtom)

  const description = HEADER_DESCRIPTIONS[category]

  return (
    <HeaderCellWrapper onClick={handleSortCategory} justify={justify}>
      {sortMethod === category && (
        <>
          {sortAscending ? (
            <ArrowChangeUp width={16} height={16} color={theme.textPrimary} />
          ) : (
            <ArrowChangeDown width={16} height={16} color={theme.textSecondary} />
          )}
        </>
      )}
      {category}
      {description && (
        <MouseoverTooltip text={description} placement="right">
          <InfoIconContainer>
            <Info width="16px" height="16px" />
          </InfoIconContainer>
        </MouseoverTooltip>
      )}
    </HeaderCellWrapper>
  )
}
