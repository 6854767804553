import { Trans } from '@lingui/macro'
import Column from 'components/Column'
import { RowBetween } from 'components/Row'
import { scrollbarStyle } from 'components/SearchModal/CurrencyList/index.css'
import {
  ConfirmationModalContent2222,
  TransactionConfirmationModal22222,
} from 'components/TransactionConfirmationModal'
import { useRef, useState } from 'react'
import { FixedSizeList } from 'react-window'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { shortenAddress } from 'utils'
import { formatAmount } from 'utils/formatAmout'

const StyledHistoryList = styled.div`
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid ${({ theme }) => theme.sectionBorder};
  height: 300px;
  overflow-y: auto;
  .address,
  .time {
    text-decoration: underline;
  }
`

interface HistoryModalProps {
  referralsList: any
  isOpen: boolean
  onDismiss: () => void
}

export default function HistoryListModal({ referralsList, isOpen, onDismiss }: HistoryModalProps) {
  const fixedListRef = useRef<FixedSizeList>()

  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [txError, setTxError] = useState<string>('')
  const pendingText = 'pending ...'

  return (
    <TransactionConfirmationModal22222
      isOpen={isOpen}
      onDismiss={onDismiss}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      txError={txError}
      width="400px"
      pendingText={pendingText}
      reviewContent={
        <ConfirmationModalContent2222
          title={<Trans>Referral History</Trans>}
          onDismiss={onDismiss}
          topContent={
            <StyledHistoryList className={scrollbarStyle}>
              <Column gap="16px">
                {referralsList?.map((referral: any, i: number) => (
                  <RowBetween className="item" key={i}>
                    <ThemedText.TextPrimary fontSize={16} className="address">
                      {shortenAddress(referral.wallet_address)}
                    </ThemedText.TextPrimary>
                    <ThemedText.TextTertiary fontSize={16} className="time">
                      {formatAmount(referral.pts_total, 2, true)} PTS.
                    </ThemedText.TextTertiary>
                  </RowBetween>
                ))}
              </Column>
            </StyledHistoryList>
          }
        />
      }
    />
  )
}
