import { useAtomValue } from 'jotai/utils'
import { useFetchVoter } from 'pages/Vote/hooks'
import { VoteDataProps } from 'pages/Vote/types'
import { useMemo } from 'react'

import {
  LeaderboardfilterStringAtom,
  LeaderboardsortAscendingAtom,
  LeaderboardSortMethod,
  LeaderboardsortMethodAtom,
} from './state'

export function useLeaderboardData() {
  const { voteList } = useFetchVoter()

  const sortMethod = useAtomValue(LeaderboardsortMethodAtom)
  const sortAscending = useAtomValue(LeaderboardsortAscendingAtom)

  const sortData = useMemo(() => {
    if (!voteList) return
    let voteArray = voteList
    switch (sortMethod) {
      case LeaderboardSortMethod.TVL:
        voteArray = voteArray.sort((a, b) => Number(b.tvl || 0) - Number(a.tvl || 0))
        break

      case LeaderboardSortMethod.VOLUME:
        voteArray = voteArray.sort((a, b) => {
          return (
            Number(b.totalVolume?.toString() || 0) * Number(b.token0.derivedETH || 0) * Number(b.ethPrice || 0) -
            Number(a.totalVolume?.toString() || 0) * Number(a.token0.derivedETH || 0) * Number(a.ethPrice || 0)
          )
        })
        break
      case LeaderboardSortMethod.TOTAL_REWARDS:
        voteArray = voteArray.sort((a, b) => Number(b.swapMinningrewardUSD || 0) - Number(a.swapMinningrewardUSD || 0))
        break
      case LeaderboardSortMethod.MY_VOLUME:
        voteArray = voteArray.sort((a, b) => {
          return (
            Number(b.myVolumes?.toString() || 0) * Number(b.token0.derivedETH || 0) * Number(b.ethPrice || 0) -
            Number(a.totalVolume?.toString() || 0) * Number(a.token0.derivedETH || 0) * Number(a.ethPrice || 0)
          )
        })
        break
    }
    return sortAscending ? voteArray.reverse() : voteArray
  }, [sortAscending, sortMethod, voteList])

  const filteredVote = useFilteredVote(sortData)

  return filteredVote
}

const useFilteredVote = (voteData: VoteDataProps[] | undefined) => {
  const filterString = useAtomValue(LeaderboardfilterStringAtom)

  const lowercaseFilterString = useMemo(() => filterString.toLowerCase(), [filterString])

  return useMemo(() => {
    if (!voteData) return undefined
    let returnDatas = voteData
    if (lowercaseFilterString) {
      returnDatas = returnDatas?.filter((token) => {
        const allStrsymbol = token.token0.symbol + token.token1.symbol
        const allStrNAME = token.token0.name + token.token1.name
        const nameIncludesFilterString = allStrNAME.toLowerCase().includes(lowercaseFilterString)
        const symbolIncludesFilterString = allStrsymbol.toLowerCase().includes(lowercaseFilterString)
        return nameIncludesFilterString || symbolIncludesFilterString
      })
    }
    return returnDatas
  }, [lowercaseFilterString, voteData])
}
