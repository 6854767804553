import { LinearProgress, linearProgressClasses } from '@mui/material'
import styled from 'styled-components/macro'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: '100%',
  height: 20,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.progressPrimary,
    height: 20,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.removeBackground,
  },
}))
type dataPros = {
  value: any
}

const CumulativeLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: 200,
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.progressPrimary,
    height: 5,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.cumulativeBarBg,
  },
}))

const TradeLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: 200,
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.progressPrimary,
    height: 5,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.primaryBtn,
  },
}))

export default function ProgressBar({ value }: dataPros) {
  return <BorderLinearProgress variant="determinate" value={value} />
}

export function CumulativeProgressBar({ value }: dataPros) {
  return <CumulativeLinearProgress variant="determinate" value={value} />
}

export function TradeProgressBar({ value }: dataPros) {
  return <TradeLinearProgress variant="determinate" value={value} />
}
