import { useQuery } from '@apollo/client'
import { useActiveChainId } from 'connection/useActiveChainId'
import gql from 'graphql-tag'
import { useMemo } from 'react'

export const useQueryTotalVolume = () => {
  const { account } = useActiveChainId()
  // ${account?.toLowerCase()}
  // 0xdffb0f41852f96042c778c2ce8b0aa890a248501
  const query = gql`
    {
      lpRecords(where: { account: "${account?.toLowerCase()}", end: "0" }) {
        id
        account
        amount0
        amount1
        amountUSD
        start
        end
      }
      userVolumes(where: { userAddress: "${account?.toLowerCase()}" }) {
        id
        userAddress
        totalValume
      }
    }
  `
  const { data, loading: isLoading, error } = useQuery(query)

  return useMemo(
    () => ({
      error,
      isLoading,
      data,
    }),
    [data, error, isLoading]
  )
}

export const useQueryUserVolumes = () => {
  const { account } = useActiveChainId()
  //${account?.toLowerCase()}
  const query = gql`
    {
      userVolumes(where: { userAddress: "${account?.toLowerCase()}" }) {
        id
        weeklyId
        userAddress
        totalValume
      }
    }
  `
  const { data, loading: isLoading, error } = useQuery(query)

  return useMemo(
    () => ({
      error,
      isLoading,
      data,
    }),
    [data, error, isLoading]
  )
}
