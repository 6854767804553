import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { CurrencyAmount, Percent, Token } from '@uniswap/sdk-core'
import SWAPMINGABI from 'abis/swapMinning.json'
import { ClaimButton, Pending } from 'components/Button'
import Column from 'components/Column'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { IconLoadingBubble, LoadingBubble, MediumLoadingBubble, SmallLoadingBubble } from 'components/Loading'
import Row from 'components/Row'
import { StyledTableRow } from 'components/Table'
import { ToastError } from 'components/Toast'
import { MAX_WIDTH_MEDIA_BREAKPOINT, SMALL_MEDIA_BREAKPOINT } from 'components/Tokens/constants'
import { useActiveChainId, useEthersSigner } from 'connection/useActiveChainId'
import { useCurrency, useToken } from 'hooks/Tokens'
import { useSwapMinning } from 'hooks/useContract'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { TextLeftCell, TextRightCell } from 'pages/Lock/LockRow'
import AddBribesModal from 'pages/Vote/AddBribesModal'
import { rewardPorps, VoteDataProps } from 'pages/Vote/types'
import { TotalRewardsItem } from 'pages/Vote/VoteRow'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode, useMemo, useState } from 'react'
import CountUp from 'react-countup'
import { NavLink } from 'react-router-dom'
import { useVoteCurrentId } from 'state/http/hooks'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled, { css } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { getContract } from 'utils'
import { getCurrRound, getWeeklyId } from 'utils/CurrRound'
import { formatAmount } from 'utils/formatAmout'
import { formatCurrencyAmount, NumberType } from 'utils/formatCurrency'
import { handlerError } from 'utils/formatError'
import { unwrappedToken } from 'utils/unwrappedToken'

import { ReactComponent as AddIcon } from '../../assets/imgs/add.svg'
import HeaderCell from './HeaderCell'
import { LeaderboardSortMethod } from './state'

const StyledLeaderboardRow = styled(StyledTableRow)<{
  first?: boolean
  last?: boolean
  $loading?: boolean
}>`
  align-items: flex-start;
  grid-template-columns: 200px 1fr 1fr 1fr 1fr 1fr;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  min-width: 390px;
  padding: 0;
  margin: 0;
  ${({ first, last }) => css`
    /* height: ${first || last ? '72px' : '64px'}; */
  `}
  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => css`background-color ${duration.medium} ${timing.ease}`};
  transition-duration: ${({ theme }) => theme.transition.duration.fast};

  &:hover {
    ${({ $loading, theme }) =>
      !$loading &&
      css`
        background-color: ${theme.hoverDefault};
      `}
    ${({ last }) =>
      last &&
      css`
        border-radius: 0px 0px 8px 8px;
      `}
  }

  .bg {
    height: 100%;
    ${({ theme }) =>
      css`
        background-color: ${theme.hoverDefault};
      `}
  }
`

const StyledTrRow = styled(StyledLeaderboardRow)``

const StyledHeaderRow = styled(StyledLeaderboardRow)`
  height: 40px;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.textPrimary};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  &:hover {
    background-color: transparent;
  }

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    justify-content: space-between;
  }
`

/* Token Row: skeleton row component */
function LeaderboardRow({
  header,
  pools,
  tvl,
  volume,
  totalRewards,
  myVolume,
  earn,
  ...rest
}: {
  first?: boolean
  header: boolean
  $loading?: boolean
  totalRewards: ReactNode
  tvl: ReactNode
  volume: ReactNode
  pools: ReactNode
  myVolume: ReactNode
  earn: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowHeaderCells = (
    <>
      <TextLeftCell data-testid="pools-cell">{pools}</TextLeftCell>
      <TextRightCell data-testid="tvl-cell">{tvl}</TextRightCell>
      <TextRightCell data-testid="volume-cell">{volume}</TextRightCell>
      <TextRightCell data-testid="total-rewards-cell">{totalRewards}</TextRightCell>
      <TextRightCell data-testid="my-volume-cell" className="bg">
        {myVolume}
      </TextRightCell>
      <TextRightCell data-testid="my-leaderboard-cell" className="bg">
        {earn}
      </TextRightCell>
    </>
  )

  const rowTrCells = (
    <>
      <TextLeftCell data-testid="pools-cell">
        <Box p="16px 0" width="100%" height="100%">
          {pools}
        </Box>
      </TextLeftCell>
      <TextRightCell data-testid="tvl-cell">
        <Box p="16px 0" width="100%" height="100%">
          {tvl}
        </Box>
      </TextRightCell>
      <TextRightCell data-testid="volume-cell">
        <Box p="16px 0" width="100%" height="100%">
          {volume}
        </Box>
      </TextRightCell>
      <TextRightCell data-testid="total-rewards-cell">
        <Box p="16px 0" width="100%" height="100%">
          {totalRewards}
        </Box>
      </TextRightCell>
      <TextRightCell data-testid="my-volume-cell" className="bg">
        <Box p="16px 0" width="100%" height="100%">
          {myVolume}
        </Box>
      </TextRightCell>
      <TextRightCell data-testid="my-leaderboard-cell" className="bg">
        <Box p="16px 0" width="100%" height="100%">
          {earn}
        </Box>
      </TextRightCell>
    </>
  )
  if (header) return <StyledHeaderRow data-testid="leaderboard-header-row">{rowHeaderCells}</StyledHeaderRow>
  return <StyledTrRow {...rest}>{rowTrCells}</StyledTrRow>
}

/* Header Row: top header row component for table */
export function HeaderRow() {
  return (
    <LeaderboardRow
      header={true}
      pools={<Trans>{LeaderboardSortMethod.POOLS}</Trans>}
      tvl={
        <Row justify="end">
          <HeaderCell category={LeaderboardSortMethod.TVL} />
        </Row>
      }
      volume={
        <Row justify="end">
          <HeaderCell category={LeaderboardSortMethod.VOLUME} />
        </Row>
      }
      totalRewards={
        <Row justify="end">
          <HeaderCell category={LeaderboardSortMethod.TOTAL_REWARDS} />
        </Row>
      }
      myVolume={
        <Row justify="end">
          <HeaderCell category={LeaderboardSortMethod.MY_VOLUME} />
        </Row>
      }
      earn={
        <Row justify="end">
          <Trans>{LeaderboardSortMethod.EARN}</Trans>
        </Row>
      }
    />
  )
}
/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <LeaderboardRow
      header={false}
      $loading
      pools={
        <Row>
          <IconLoadingBubble width="40px" height="40px" />
          <MediumLoadingBubble />
        </Row>
      }
      tvl={
        <Column align="end" width="100%" gap="xs">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
          <MediumLoadingBubble />
        </Column>
      }
      volume={
        <Column align="end" width="100%" gap="xs">
          <LoadingBubble />
        </Column>
      }
      totalRewards={
        <Column align="end" width="100%" gap="xs">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
          <Row align="center" justify="flex-end" gap="sm">
            <SmallLoadingBubble />
            <AddIcon />
          </Row>
        </Column>
      }
      myVolume={
        <Column align="end" width="100%" gap="xs">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
        </Column>
      }
      earn={
        <Column align="end" width="100%" gap="xs">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
          <ClaimButton>Claim</ClaimButton>
        </Column>
      }
      {...props}
    />
  )
}

export interface LoadedRowProps {
  leaderboardListIndex: number
  leaderboardListLength: number
  leaderboard: VoteDataProps
}

export const TotalLeaderboardEarnsItem = ({ rewardAddr, swapMing }: { rewardAddr: rewardPorps; swapMing: string }) => {
  const { chainId } = useActiveChainId()
  const currenty = useMemo(
    () =>
      chainId
        ? new Token(
            chainId,
            rewardAddr.tokenInfo.id,
            Number(rewardAddr.tokenInfo.decimals),
            rewardAddr.tokenInfo.symbol,
            rewardAddr.tokenInfo.name
          )
        : undefined,
    [
      chainId,
      rewardAddr.tokenInfo.decimals,
      rewardAddr.tokenInfo.id,
      rewardAddr.tokenInfo.name,
      rewardAddr.tokenInfo.symbol,
    ]
  )
  const tokenContract = useSwapMinning(swapMing)
  const { account } = useActiveChainId()
  const currRound = getCurrRound()

  const { result } = useSingleCallResult(tokenContract, 'earned', [rewardAddr.tokenInfo.id, account, currRound])

  const tokenInfo = useMemo(() => {
    if (!result || !currenty) return
    return CurrencyAmount.fromRawAmount(currenty, result[0])
  }, [currenty, result])
  return (
    <ThemedText.TextRewards>
      <CountUp preserveValue={true} end={Number(tokenInfo?.toSignificant()) || 0} separator="," decimals={2} />{' '}
      {currenty?.symbol}
    </ThemedText.TextRewards>
  )
}

/* Loaded State: row component with token information */
export const LoadedRow = forwardRef((props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { leaderboardListIndex, leaderboardListLength, leaderboard } = props
  const [showAddBribes, setShowAddBribes] = useState(false)
  const handleDismissAddBribes = () => {
    setShowAddBribes(false)
  }
  const handleOpen = (e: any) => {
    e.preventDefault()
    setShowAddBribes(true)
  }
  const token0 = useCurrency(leaderboard.token0.id)
  const provder = useEthersSigner()
  const { account } = useActiveChainId()
  const currRound = getCurrRound()

  const totalVolume = useMemo(() => {
    if (!token0 || !leaderboard.totalVolume) return
    return CurrencyAmount.fromRawAmount(token0, leaderboard.totalVolume.toString())
  }, [leaderboard.totalVolume, token0])

  const myVolume = useMemo(() => {
    if (!token0 || !leaderboard.myVolumes) return
    return CurrencyAmount.fromRawAmount(token0, leaderboard.myVolumes.toString())
  }, [leaderboard.myVolumes, token0])

  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [txError, setTxError] = useState<string>('')
  const pendingText = 'pending ...'
  const addTransaction = useTransactionAdder()

  const onClaim = async (e: any, data: any) => {
    setAttemptingTxn(true)
    e.preventDefault()
    try {
      const swapMIng = getContract(data.swapMinningAddress, SWAPMINGABI.abi, provder)
      if (!swapMIng || !account) return
      const response = await swapMIng.getReward(account, currRound)
      setAttemptingTxn(false)
      addTransaction(response, {
        type: TransactionType.CLAIM_REWARDS,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      ToastError(handlerError(error))
      console.log('claimRewards error', error)
    }
  }

  const epochNum = useVoteCurrentId()
  const currentEpochId = getWeeklyId()
  const isCurrEpoch = useMemo(() => {
    return currentEpochId === epochNum
  }, [currentEpochId, epochNum])

  const leaderboardToken0 = useToken(leaderboard.token0.id)
  const leaderboardToken1 = useToken(leaderboard.token1.id)
  const currency0 = leaderboardToken0 ? unwrappedToken(leaderboardToken0) : undefined
  const currency1 = leaderboardToken1 ? unwrappedToken(leaderboardToken1) : undefined

  return (
    <NavLink to={`/leaderboardDetail/${leaderboard.lpAddr}`} style={{ textDecoration: 'none' }}>
      <div ref={ref} data-testid={`leaderboard-table-row-${leaderboard.lpAddr}`}>
        <LeaderboardRow
          header={false}
          pools={
            <Row>
              <DoubleCurrencyLogo currency0={currency1} currency1={currency0} size={40} margin />
              <Column align="flex-start" gap="xs" ml="16px">
                <ThemedText.TextPrimary fontWeight={700} fontSize={16}>
                  {leaderboard.token0.symbol}-{leaderboard.token1.symbol}
                </ThemedText.TextPrimary>
                <ThemedText.TextSecondary fontSize={14}>
                  {new Percent(leaderboard.poolFee, 1_000_000).toSignificant()}%
                </ThemedText.TextSecondary>
              </Column>
            </Row>
          }
          tvl={
            <Column align="end" width="100%" gap="xs">
              <ThemedText.TextPrimary fontWeight={700}>
                ${formatAmount(leaderboard.tvl, 2, true)}
              </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>
                {formatCurrencyAmount(leaderboard.liqvidityToken0, NumberType.FiatTokenStats2)}{' '}
                {leaderboard.token0.symbol}
              </ThemedText.TextSecondary>
              <ThemedText.TextSecondary fontSize={14}>
                {formatCurrencyAmount(leaderboard.liqvidityToken1, NumberType.FiatTokenStats2)}{' '}
                {leaderboard.token1.symbol}
              </ThemedText.TextSecondary>
            </Column>
          }
          volume={
            <Column align="end" width="100%" gap="xs">
              <ThemedText.TextPrimary fontWeight={700}>
                $
                {formatAmount(
                  Number(totalVolume?.toSignificant()) *
                    Number(leaderboard.token0.derivedETH) *
                    Number(leaderboard.ethPrice) || 0,
                  2,
                  true
                )}
              </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>
                {formatCurrencyAmount(totalVolume, NumberType.FiatTokenStats2)} {totalVolume?.currency.symbol}
              </ThemedText.TextSecondary>
            </Column>
          }
          totalRewards={
            <Column align="end" width="100%" gap="xs">
              <ThemedText.TextPrimary fontWeight={700}>
                ${formatAmount(leaderboard.swapMinningrewardUSD, 2, true)}
              </ThemedText.TextPrimary>
              {leaderboard?.swapMinningrewardsLists?.map((item, index: number) => {
                return <TotalRewardsItem key={'leaderboard-rewardItem' + index} rewardAddr={item} />
              })}
              {isCurrEpoch && (
                <AddIcon
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    handleOpen(e)
                  }}
                />
              )}
            </Column>
          }
          myVolume={
            <Column align="end" width="100%" gap="xs">
              <ThemedText.TextPrimary fontWeight={700}>
                $
                {formatAmount(
                  Number(myVolume?.toSignificant()) *
                    Number(leaderboard.token0.derivedETH) *
                    Number(leaderboard.ethPrice),
                  2,
                  true
                )}
              </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>
                {formatCurrencyAmount(myVolume, NumberType.FiatTokenStats2)} {myVolume?.currency.symbol}
              </ThemedText.TextSecondary>
            </Column>
          }
          earn={
            <Column align="end" width="100%" gap="xs">
              {leaderboard.swapMinningrewardsLists && leaderboard.swapMinningrewardsLists?.length > 0 ? (
                leaderboard.swapMinningrewardsLists?.map((item: any, index: number) => {
                  return (
                    <TotalLeaderboardEarnsItem
                      key={'earn-leaderboard' + index}
                      rewardAddr={item}
                      swapMing={leaderboard.swapMinningAddress}
                    />
                  )
                })
              ) : (
                <ThemedText.TextRewards>--</ThemedText.TextRewards>
              )}
              <Row justify="end">
                <ClaimButton disabled={attemptingTxn} onClick={(e) => onClaim(e, leaderboard)}>
                  {attemptingTxn ? <Pending /> : 'Claim'}
                </ClaimButton>
              </Row>
            </Column>
          }
          first={leaderboardListIndex === 0}
          last={leaderboardListIndex === leaderboardListLength - 1}
        />
      </div>
      <AddBribesModal
        brigeType="Trade"
        isOpen={showAddBribes}
        details={leaderboard}
        onDismiss={handleDismissAddBribes}
      />
    </NavLink>
  )
})

LoadedRow.displayName = 'LoadedRow'
