import { useState } from 'react'
import { Box } from 'rebass'
import styled from 'styled-components/macro'

import { ChartInfoCard } from '../ChartInfo'
import { useGetPositions, useOrderList } from '../Hooks'
import OrderList from './LimitList/index'
import NavTab from './NavTab'
import PositionTableList from './PositionTableList'
const NavTabBox = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.swapSectionBorder};
`
function NavTabList() {
  const [curr, setCurr] = useState<number>(0)

  const positions = useGetPositions()
  const orderlist = useOrderList()
  const dataLable = [`Position(${positions?.length || 0})`, `Order(${orderlist?.length || 0})`, 'History']

  return (
    <ChartInfoCard>
      <NavTabBox pl="18px">
        <NavTab Tabtype={1} tabList={dataLable} curr={curr} setCurr={setCurr} />
      </NavTabBox>
      {curr == 0 && <PositionTableList />}
      {curr == 1 && <OrderList />}
    </ChartInfoCard>
  )
}

export default NavTabList
