import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { Currency, Percent } from '@uniswap/sdk-core'
import { ButtonConfirmed } from 'components/Button'
import { useDynamicApprove } from 'components/DynamicApprove'
import Row from 'components/Row'
import {
  ConfirmationModalContent2222,
  TransactionConfirmationModal22222,
} from 'components/TransactionConfirmationModal'
import dayjs from 'dayjs'
import { useMasterChefV3, useSwapMinning, useVotingReward } from 'hooks/useContract'
import { useInputAmount } from 'hooks/useInputAmount'
import LockAmount from 'pages/Lock/LockModal/CreateLockModal/LockAmount'
import LockPicker from 'pages/Lock/LockModal/CreateLockModal/LockPicker'
import { StyledCheckbox } from 'pages/Lock/LockModal/ManageModal'
import { useMemo, useState } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled, { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { getEndRound } from 'utils/CurrRound'
import { handlerError } from 'utils/formatError'

import { VoteDataProps } from '../types'
import { useListTokens } from './hooks'

const TypesBox = styled(Row)`
  background-color: ${({ theme }) => theme.backgroundInteractive};
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  padding: 16px;
  border-radius: 16px;
  margin-top: 8px;
`
interface AddBribesModalProps {
  brigeType: string //brigeType="VOTE" "LP" "Trade"
  isOpen: boolean
  onDismiss: () => void
  details?: VoteDataProps
}

const time = getEndRound()
const day = dayjs.unix(time).format('YYYY/MM/DD')
export default function AddBribesModal({ brigeType, isOpen, onDismiss, details }: AddBribesModalProps) {
  const theme = useTheme()
  const votingContract = useVotingReward(details?.votingRewardAddress)
  const masterChefContract = useMasterChefV3(details?.masterChefAddress)
  const swapMinningContract = useSwapMinning(details?.swapMinningAddress)
  const brigeContract = useMemo(
    () => (brigeType == 'VOTE' ? votingContract : brigeType == 'LP' ? masterChefContract : swapMinningContract),
    [brigeType, masterChefContract, swapMinningContract, votingContract]
  )

  const tokens = useListTokens(brigeContract)

  const [currenty, setcurrenty] = useState<Currency | undefined>()

  const { handleUserInput, inputAmount, setInputAmount, isInputValid } = useInputAmount(currenty)

  const setCURRENY = (data: any) => {
    setcurrenty(data.amount.currency)
    setInputAmount(data)
  }

  const { DynamicApprove, isApproved } = useDynamicApprove([inputAmount.amount], brigeContract?.address)

  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [txError, setTxError] = useState<string>('')
  const pendingText = 'pending ...'

  const handleDismissConfirmation = () => {
    onDismiss && onDismiss()
    setTimeout(() => {
      setAttemptingTxn(false)
      setTxHash('')
      setTxError('')
    }, 300)
  }
  const addTransaction = useTransactionAdder()

  const Add = async () => {
    const amount = inputAmount?.amount?.numerator.toString()
    const token = inputAmount.amount?.currency.wrapped.address
    if (!amount || !brigeContract || !token) return
    setAttemptingTxn(true)
    try {
      const response = await brigeContract.notifyRewardAmount(token, amount)
      addTransaction(response, {
        type: TransactionType.ADD_BRIBES,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      console.log('error', error)
    }
  }

  const [defaultCheckedValue, setCheckValue] = useState(false)

  const modalContent = (
    <>
      <ThemedText.TextSecondary fontWeight={700} fontSize={14}>
        <Trans>Types</Trans>
      </ThemedText.TextSecondary>
      <TypesBox>
        <ThemedText.TextSecondary fontWeight={700} fontSize={14}>
          <Trans>{brigeType}</Trans>
        </ThemedText.TextSecondary>
        <ThemedText.TextSecondary fontWeight={700} fontSize={14} ml="12px" mr="12px">
          <Trans>|</Trans>
        </ThemedText.TextSecondary>
        <ThemedText.TextSecondary fontWeight={700} fontSize={14}>
          {details?.token0.symbol}-{details?.token1.symbol}{' '}
          {new Percent(details?.poolFee || 0, 1_000_000).toSignificant()}%
        </ThemedText.TextSecondary>
      </TypesBox>
      <Box mt="16px">
        <LockAmount
          tokens={tokens}
          handleUserInput={handleUserInput}
          inputAmount={inputAmount}
          setInputAmount={setCURRENY}
        />
      </Box>
      <LockPicker title="Ends in" />
      <ThemedText.TextSecondary fontWeight={700} fontSize={14}>
        {day}
      </ThemedText.TextSecondary>
      <Box
        style={{ cursor: 'pointer' }}
        bgcolor={theme.agreeBg}
        padding="8px 0"
        borderRadius="8px"
        display="flex"
        onClick={() => setCheckValue(!defaultCheckedValue)}
      >
        <StyledCheckbox checked={defaultCheckedValue} />
        {defaultCheckedValue ? (
          <ThemedText.TextPrimary fontWeight={700} fontSize={14}>
            I understand that I will be unable to withdraw token bribes (incentives) for this pair after providing them.
          </ThemedText.TextPrimary>
        ) : (
          <ThemedText.TextTertiary fontWeight={700} fontSize={14}>
            I understand that I will be unable to withdraw token bribes (incentives) for this pair after providing them.
          </ThemedText.TextTertiary>
        )}
      </Box>
      <DynamicApprove />
      <Box mt="8px">
        <ButtonConfirmed disabled={!isApproved || !isInputValid || !defaultCheckedValue} onClick={Add}>
          Add
        </ButtonConfirmed>
      </Box>
    </>
  )

  return (
    <TransactionConfirmationModal22222
      isOpen={isOpen}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      txError={txError}
      onDismiss={handleDismissConfirmation}
      width="500px"
      pendingText={pendingText}
      reviewContent={
        <ConfirmationModalContent2222
          title="Add Bribes"
          onDismiss={handleDismissConfirmation}
          topContent={modalContent}
        />
      }
    />
  )
}
