import { Trans } from '@lingui/macro'
import { Box, useMediaQuery } from '@mui/material'
import { ReactComponent as LeaderBoradIcon } from 'assets/imgs/airdrop/leaderBorad.svg'
import axios from 'axios'
import { StyledWinIcon } from 'components/Icons/StyledIcon'
import { PageWrapper } from 'components/swap/styleds'
import { AIRDROP_API } from 'constants/misc'
import { NoTokensStateM } from 'pages/Liquidity/Table'
import { useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'
import { shortenAddress } from 'utils'
import { formatAmount } from 'utils/formatAmout'

const LeaderBoradTilte = styled.div`
  position: relative;
  .title {
    position: absolute;
    left: 46px;
    bottom: 0;
  }
`

const RankingPage = styled(Box)`
  padding: 10px 16px 22px 16px;
  background: ${({ theme }) => (theme.darkMode ? 'rgba(40, 40, 40, 0.5)' : 'rgba(0, 0, 0, 0.05)')};
  border: 1px solid ${({ theme }) => theme.primaryBtn};
  border-radius: 16px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    padding: 10px 8px 22px 8px;
  }
`

const StyledTr = styled(Box)`
  display: grid;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  grid-template-columns: 100px 200px 1fr;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    grid-template-columns: 50px 120px 1fr;
  }
`

const StyledThHeader = styled(StyledTr)`
  border-bottom: 1px solid ${({ theme }) => theme.swapSectionBorder};
`

const StyledTh = styled(StyledTr)`
  &.rank1 {
    background: rgba(1, 147, 210, 0.4);
  }
  &.rank2 {
    background: rgba(1, 147, 210, 0.2);
  }
  &.rank3 {
    background: rgba(1, 147, 210, 0.1);
  }
  &.border {
    border-bottom: 1px dashed ${({ theme }) => theme.swapSectionBorder};
  }
`

const StyledTd = styled(Box)`
  padding: 0 8px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    padding: 0 6px;
  }
`

export default function Ranking() {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.md}px)`)
  const [rankingList, setRankingList] = useState<any>([])
  useEffect(() => {
    axios
      .get(`${AIRDROP_API}/dashboard/`)
      .then((response) => {
        setRankingList(response.data)
      })
      .catch((error) => {
        console.error('dashboard error', error)
      })
  }, [])

  return (
    <PageWrapper data-testid="airdrop-ranking-page">
      <Box
        display="flex"
        flexDirection={isSmallScreen ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems={isSmallScreen ? 'flex-start' : 'center'}
        gap={isSmallScreen ? '16px' : '8px'}
      >
        <LeaderBoradTilte>
          <StyledWinIcon />
          <ThemedText.TextPrimary
            fontSize={isSmallScreen ? 36 : 48}
            fontWeight={800}
            className="title active-gradient-text"
          >
            <Trans>LeaderBoard</Trans>
          </ThemedText.TextPrimary>
        </LeaderBoradTilte>
        <Box display="flex" width={isSmallScreen ? '100%' : 'auto'} justifyContent="flex-end">
          <LeaderBoradIcon />
        </Box>
      </Box>
      <RankingPage>
        <StyledThHeader>
          <StyledTd>
            <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
              <Trans>#</Trans>
            </ThemedText.TextSecondary>
          </StyledTd>
          <StyledTd>
            <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
              <Trans>Address</Trans>
            </ThemedText.TextSecondary>
          </StyledTd>
          <StyledTd>
            <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
              <Trans>PTS.</Trans>
            </ThemedText.TextSecondary>
          </StyledTd>
        </StyledThHeader>
        {rankingList?.length > 0 ? (
          rankingList?.map((item: any, i: number) => (
            <StyledTh key={i} className={i == 0 ? 'rank1' : i == 1 ? 'rank2' : i == 2 ? 'rank3' : 'border'}>
              <StyledTd>
                <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                  {i + 1}
                </ThemedText.TextSecondary>
              </StyledTd>
              <StyledTd>
                <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                  {shortenAddress(item.wallet_address)}
                </ThemedText.TextSecondary>
              </StyledTd>
              <StyledTd>
                <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                  {formatAmount(item.pts_total, 2, true)} PTS.
                </ThemedText.TextSecondary>
              </StyledTd>
            </StyledTh>
          ))
        ) : (
          <NoTokensStateM message={<Trans>No rankingList found</Trans>} />
        )}
      </RankingPage>
    </PageWrapper>
  )
}
