import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import { MediumLoadingBubble } from 'components/Loading'
import Row from 'components/Row'
import { Cell, StyledTableRow } from 'components/Table'
import { MAX_WIDTH_MEDIA_BREAKPOINT } from 'components/Tokens/constants'
import { IPoolSwapUser } from 'hooks/useQueryLiquidity'
import { TextLeftCell } from 'pages/Lock/LockRow'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode, useMemo } from 'react'
import styled, { css } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { shortenAddress } from 'utils/addresses'
import { formatAmount } from 'utils/formatAmout'

import { TokenSortMethod } from './state'

const StyledLeaderboardRow = styled(StyledTableRow)<{
  first?: boolean
  last?: boolean
  $loading?: boolean
}>`
  align-items: stretch;
  grid-template-columns: 0.5fr 1.5fr 2fr 2fr 2fr;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  min-width: 390px;
  padding: 0;
  margin: 0;
  ${({ first, last }) => css`
    /* height: ${first || last ? '72px' : '64px'}; */
  `}
  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => css`background-color ${duration.medium} ${timing.ease}`};
  transition-duration: ${({ theme }) => theme.transition.duration.fast};

  &:hover {
    ${({ $loading, theme }) =>
      !$loading &&
      css`
        background-color: ${theme.hoverDefault};
      `}
    ${({ last }) =>
      last &&
      css`
        border-radius: 0px 0px 8px 8px;
      `}
  }
`

const StyledTrRow = styled(StyledLeaderboardRow)``

const StyledHeaderRow = styled(StyledLeaderboardRow)`
  height: 40px;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.textPrimary};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  &:hover {
    background-color: transparent;
  }
`

const RankCell = styled(Cell)`
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 8px;
  min-width: 80px;
`

/* Token Row: skeleton row component */
function LeaderboardRow({
  header,
  rank,
  trader,
  swapVolume,
  preVolume,
  totalVolume,
  // Rewards,
  ...rest
}: {
  first?: boolean
  header: boolean
  $loading?: boolean
  preVolume: ReactNode
  trader: ReactNode
  swapVolume: ReactNode
  rank: ReactNode
  totalVolume: ReactNode
  // Rewards: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowHeaderCells = (
    <>
      <RankCell data-testid="rank-cell">{rank}</RankCell>
      <TextLeftCell data-testid="trader-cell">{trader}</TextLeftCell>
      <TextLeftCell data-testid="swap-volume-cell">{swapVolume}</TextLeftCell>
      <TextLeftCell data-testid="pre-volume-cell">{preVolume}</TextLeftCell>
      <TextLeftCell data-testid="my-volume-cell">{totalVolume}</TextLeftCell>
      {/* <TextRightCell data-testid="my-leaderboard-cell">{Rewards}</TextRightCell> */}
    </>
  )
  const rowTrCells = (
    <>
      <RankCell data-testid="rank-cell">
        <Box p="16px 0" width="100%" height="100%">
          {rank}
        </Box>
      </RankCell>
      <TextLeftCell data-testid="trader-cell">
        <Box p="16px 0" width="100%" height="100%">
          {trader}
        </Box>
      </TextLeftCell>
      <TextLeftCell data-testid="swap-volume-cell">
        <Box p="16px 0" width="100%" height="100%">
          {swapVolume}
        </Box>
      </TextLeftCell>
      <TextLeftCell data-testid="pre-volume-cell">
        <Box p="16px 0" width="100%" height="100%">
          {preVolume}
        </Box>
      </TextLeftCell>
      <TextLeftCell data-testid="my-volume-cell">
        <Box p="16px 0" width="100%" height="100%">
          {totalVolume}
        </Box>
      </TextLeftCell>
      {/* <TextRightCell data-testid="my-leaderboard-cell">
        <Box p="16px 0" width="100%" height="100%">
          {Rewards}
        </Box>
      </TextRightCell> */}
    </>
  )
  if (header) return <StyledHeaderRow data-testid="leaderboard-header-row">{rowHeaderCells}</StyledHeaderRow>
  return <StyledTrRow {...rest}>{rowTrCells}</StyledTrRow>
}

/* Header Row: top header row component for table */
export function HeaderRow() {
  return (
    <LeaderboardRow
      header={true}
      rank={<Trans>{TokenSortMethod.RANK}</Trans>}
      trader={
        <Row>
          <Trans>{TokenSortMethod.TRADER}</Trans>
        </Row>
      }
      swapVolume={
        <Row>
          <Trans>{TokenSortMethod.SWAP_VOLUME}</Trans>
        </Row>
      }
      preVolume={
        <Row>
          <Trans>{TokenSortMethod.PRE_VOLUME}</Trans>
        </Row>
      }
      totalVolume={
        <Row>
          <Trans>{TokenSortMethod.TOTAL_VOLUME}</Trans>
        </Row>
      }
      // Rewards={
      //   <Row justify="end">
      //     <Trans>{TokenSortMethod.REWARDS}</Trans>
      //   </Row>
      // }
    />
  )
}
/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <LeaderboardRow
      header={false}
      $loading
      rank={
        <Row>
          <MediumLoadingBubble />
        </Row>
      }
      trader={
        <Row>
          <MediumLoadingBubble />
        </Row>
      }
      swapVolume={
        <Row>
          <MediumLoadingBubble />
        </Row>
      }
      preVolume={
        <Row>
          <MediumLoadingBubble />
        </Row>
      }
      totalVolume={
        <Row>
          <MediumLoadingBubble />
        </Row>
      }
      // Rewards={
      //   <Row justify="end">
      //     <MediumLoadingBubble />
      //   </Row>
      // }
      {...props}
    />
  )
}

export interface LoadedRowProps {
  leaderboardListIndex: number
  leaderboardListLength: number
  leaderboard: IPoolSwapUser
  token?: Currency
}

/* Loaded State: row component with token information */
export const LoadedRow = forwardRef((props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { leaderboardListIndex, leaderboardListLength, leaderboard, token } = props

  const swapVolume = useMemo(() => {
    if (!token) return
    return CurrencyAmount.fromRawAmount(token, leaderboard.swapVolume)
  }, [leaderboard.swapVolume, token])

  const perpVolume = useMemo(() => {
    if (!token) return
    return CurrencyAmount.fromRawAmount(token, leaderboard.perpVolume)
  }, [leaderboard.perpVolume, token])

  const totalVolume = useMemo(() => {
    if (!token) return
    return CurrencyAmount.fromRawAmount(token, leaderboard.totalValume)
  }, [leaderboard.totalValume, token])

  return (
    <div ref={ref} data-testid={`leaderboard-detail-row-${leaderboard.userAddress}`}>
      <LeaderboardRow
        header={false}
        rank={
          <Row>
            <ThemedText.TextSecondary fontWeight={500}>#{leaderboardListIndex + 1}</ThemedText.TextSecondary>
          </Row>
        }
        trader={
          <Row>
            <ThemedText.BodySmall fontWeight={500} fontSize={16}>
              {shortenAddress(leaderboard.userAddress)}
            </ThemedText.BodySmall>
          </Row>
        }
        swapVolume={
          <Row>
            <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
              {formatAmount(swapVolume?.toSignificant(), 2, true)} {token?.symbol}
            </ThemedText.TextPrimary>
          </Row>
        }
        preVolume={
          <Row>
            <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
              {formatAmount(perpVolume?.toSignificant(), 2, true)} {token?.symbol}
            </ThemedText.TextPrimary>
          </Row>
        }
        totalVolume={
          <Row>
            <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
              {formatAmount(totalVolume?.toSignificant(), 2, true)} {token?.symbol}
            </ThemedText.TextPrimary>
          </Row>
        }
        // Rewards={
        //   <Row justify="end">
        //     <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
        //       <CountUp preserveValue={true} end={0} separator="," decimals={2} />
        //     </ThemedText.TextPrimary>
        //   </Row>
        // }
        first={leaderboardListIndex === 0}
        last={leaderboardListIndex === leaderboardListLength - 1}
      />
    </div>
  )
})

LoadedRow.displayName = 'LoadedRow'
