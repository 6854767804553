import { Trans } from '@lingui/macro'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { formatCurrencyAmount, NumberType } from '@uniswap/conedison/format'
import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import { ButtonConfirmed } from 'components/Button'
import SwapCurrencyInputPanel from 'components/CurrencyInputPanel/SwapCurrencyInputPanel'
import { useDynamicApprove } from 'components/DynamicApprove'
import { LoadingDataBubble } from 'components/Loading'
import Row, { RowBetween } from 'components/Row'
import SettingsTab from 'components/Settings'
import SwapDetails from 'components/swap/SwapDetails'
import Tab from 'components/Tab'
import { useActiveChainId } from 'connection/useActiveChainId'
import { getSwapCurrencyId } from 'constants/tokens'
import { BigNumber } from 'ethers'
import { useOrderBookContract, useRoxUtils, useTradePool, useTradeRouter } from 'hooks/useContract'
import { useInputAmount } from 'hooks/useInputAmount'
import useWrapCallback, { WrapType } from 'hooks/useWrapCallback'
import { useAtom } from 'jotai'
import { SwapSection } from 'pages/Swap/Styled'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { TRADESATE } from 'state/positionSwap/actions'
import {
  usePositionSwapActionHandlers as useSwapActionHandlers,
  usePositionSwapInfo,
  useTradeSWAPState,
} from 'state/positionSwap/hooks'
import { SwapState } from 'state/positionSwap/reducer'
import { Field } from 'state/swap/actions'
import { tryParsePlaces } from 'state/swap/hooks'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled, { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { compareAddress } from 'utils'
import { BN, formFeeWei, fromSqrt96Wei, toSqrt96 } from 'utils/bn'
import { countZeros } from 'utils/countZeros'
import { formatAmount } from 'utils/formatAmout'
import { handlerError } from 'utils/formatError'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import { useAccount } from 'wagmi'

import { FeesDetails } from './feesDetails'
import { useDetailsPool, useEstimateIncrease } from './Hooks'
import TradeConfirmationModal from './Modal/TradeConfirmModal'
import { tradeTabAtom, tradeTabTrackBgAtom } from './navState'

const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  position: relative;
  background: ${({ theme }) => theme.backgroundSurface};
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.backgroundOutline};
  padding: 14px 8px 16px 8px;
  transition: transform 250ms ease;
`

const InputSwapSection = styled(SwapSection)`
  border-radius: 12px 12px 0 0;
  background-color: ${({ theme }) => theme.swapSectionBg};
`
const OutputSwapSection = styled(SwapSection)`
  border-radius: 0 0 12px 12px;
  background-color: ${({ theme }) => theme.swapSectionBg};
`
export default function TradeRightWrapper({
  disableTokenInputs = false,
  onCurrencyChange,
}: {
  isshowSwap?: () => void
  disableTokenInputs?: boolean
  prefilledState?: Partial<SwapState>
  onCurrencyChange?: (selected: Pick<SwapState, Field.INPUT | Field.OUTPUT>) => void
}) {
  const theme = useTheme()
  const { account } = useActiveChainId()
  const [showConfirm, setShowConfirm] = useState<boolean>(false)

  const state = useTradeSWAPState()
  const navigate = useNavigate()
  const [searchParams, setobj] = useSearchParams()
  const currTab = searchParams.get('currTab')
  const location = useLocation()
  const orderbookContract = useOrderBookContract()

  // useEffect(() => {
  //   if (!account || !orderbookContract) return
  //   orderbookContract?.getPendingIncreaseOrders(account).then((Res) => {
  //     console.log({ Res })
  //   })
  // }, [account, orderbookContract])

  const [navTab, setNavTab] = useAtom(tradeTabAtom)
  const [trackBg, setTrackBg] = useAtom(tradeTabTrackBgAtom)
  useEffect(() => {
    if (navTab == 0) {
      setTrackBg(theme.claimBtn)
    } else if (navTab == 1) {
      setTrackBg(theme.long)
    } else if (navTab == 2) {
      setTrackBg(theme.short)
    }
  }, [navTab, setTrackBg, theme.claimBtn, theme.long, theme.short])

  const [limitPrice, setLimitPrice] = useState<string>('')

  useEffect(() => {
    if (location.pathname === '/perp') {
      setNavTab(1)
      setTrackBg(theme.long)
    }
    if (currTab) {
      setNavTab(Number(currTab))
      currTab == '1' && setTrackBg(theme.long)
      currTab == '2' && setTrackBg(theme.short)
    }
  }, [currTab, location.pathname, setNavTab, setTrackBg, theme.long, theme.short])

  const { onUserInput, onChangeLong, onCurrencySelection } = useSwapActionHandlers()
  const handlerChange = (i: number) => {
    setNavTab(i)

    if (i == 0) {
      // navigate('/swap')
      navigate(`/swap?currTab=0`)
    } else {
      onChangeLong(i == 1 ? TRADESATE.LONG : TRADESATE.SHORT)
    }
  }

  const { chainId } = useActiveChainId()

  const { typedValue, independentField, sliederLever, poolInfo } = state

  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const {
    loading: poolLoading,
    currentSqrtRatioX96,
    tradePool,
    tokenRatio,
    slippageTolerance,
    traderFee,
    currency0,
    currency1,
    symbol,
    twapPrice,
  } = useDetailsPool()

  const maxLiquidity = useMemo(() => {
    if (!traderFee || !currency0 || !currency1) return
    if (navTab == 1) {
      if (state.isToken0) {
        return CurrencyAmount.fromRawAmount(currency1, traderFee.liquidity0.toString())
      }
      return CurrencyAmount.fromRawAmount(currency0, traderFee.liquidity1.toString())
    }
    if (state.isToken0) {
      return CurrencyAmount.fromRawAmount(currency0, traderFee.liquidity1.toString())
    } else {
      return CurrencyAmount.fromRawAmount(currency1, traderFee.liquidity0.toString())
    }
  }, [currency0, currency1, navTab, state.isToken0, traderFee])

  const isLong = navTab === 1
  const swapInfo = usePositionSwapInfo(state, chainId, isLong, tokenRatio, traderFee, limitPrice)

  const {
    // tradePool: { isLoading, poolAddr },
    allowedSlippage,
    autoSlippage,
    currencyBalances,
    parsedAmount,
    currencies,
    useInpuntAmount,
    inputError: swapInputError,
  } = swapInfo

  const { address } = useAccount()
  const { openConnectModal } = useConnectModal()
  const handleTypeInput = useCallback(
    (value: string) => {
      if (value == undefined) return
      const parseAmount = tryParsePlaces(value, currencies[Field.INPUT] ?? undefined)

      if (!parseAmount) return

      onUserInput(Field.INPUT, value)
    },
    [currencies, onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  const maxInputAmount: CurrencyAmount<Currency> | undefined = useMemo(
    () => maxAmountSpend(currencyBalances[Field.INPUT]),
    [currencyBalances]
  )
  const { wrapType } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const parsedAmounts = useMemo(
    () =>
      showWrap
        ? {
            [Field.INPUT]: parsedAmount,
            [Field.OUTPUT]: parsedAmount,
          }
        : {
            [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : useInpuntAmount?.inputAmount,
            [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : useInpuntAmount?.outputAount,
          },
    [independentField, parsedAmount, showWrap, useInpuntAmount?.inputAmount, useInpuntAmount?.outputAount]
  )
  const { result: estimateIncrease, loading: increaseLoading } = useEstimateIncrease(
    isLong,
    tradePool,
    parsedAmounts[Field.INPUT]?.numerator.toString(),
    currentSqrtRatioX96,
    false,
    currency0?.decimals,
    currency1?.decimals
  )
  const { inputAmount, handleUserInput } = useInputAmount(parsedAmounts?.[Field.INPUT]?.currency)

  const handleMaxInput = useCallback(() => {
    maxInputAmount && onUserInput(Field.INPUT, maxInputAmount.toExact())
  }, [maxInputAmount, onUserInput])

  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue,
      [dependentField]: showWrap
        ? parsedAmounts[independentField]?.toExact() ?? ''
        : formatCurrencyAmount(parsedAmounts[dependentField], NumberType.SwapTradeAmount, ''),
    }),
    [dependentField, independentField, parsedAmounts, showWrap, typedValue]
  )

  const stablecoinPriceImpact = undefined

  const addTransaction = useTransactionAdder()
  const tradePoolContract = useTradePool(tradePool)
  const tradeRouterContract = useTradeRouter()
  const RoxUtilsContract = useRoxUtils()

  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string | undefined>()
  const [txError, setTxError] = useState<string | undefined>()

  const pendingText = 'pending....'

  const handleDismissConfirmation = () => {
    setShowConfirm(false)

    setTimeout(() => {
      setAttemptingTxn(false)
      setTxHash(undefined)
      setTxError(undefined)
    }, 300)
  }

  const handelOpenType = () => {
    if (state.isLimit) {
      createLimitOrder()
    } else {
      openPosition()
    }
  }
  const openPosition: any = useCallback(async () => {
    if (
      !tradeRouterContract ||
      !account ||
      !tradePool ||
      !tradePoolContract ||
      !currency0 ||
      !currency1 ||
      !twapPrice ||
      !RoxUtilsContract ||
      !poolInfo
    )
      return
    const INPUT = parsedAmounts[Field.INPUT]?.numerator.toString()

    const inputDecimal = parsedAmounts[Field.INPUT]?.currency.decimals
    const outputDecimal = parsedAmounts[Field.OUTPUT]?.currency.decimals
    if (!INPUT || !inputDecimal || !outputDecimal) return
    setAttemptingTxn(true)

    try {
      const colAmount = INPUT
      const istoken0 = compareAddress(currency0.address, parsedAmounts[Field.INPUT]?.currency.wrapped.address)
      let lss: any
      let currentsqrtRatioX96T: any
      const tokenPrice0 = fromSqrt96Wei(twapPrice.toString(), currency0.decimals, currency1.decimals)

      if (istoken0) {
        lss = BN(INPUT).multipliedBy(tokenPrice0).div(BN(10).pow(inputDecimal)).multipliedBy(BN(10).pow(outputDecimal))
        lss = sliederLever > 0 ? BN(lss.toString()).times(sliederLever).toFixed(0) : 0
        const slippagePrice = tokenPrice0
          .multipliedBy(BN(1).plus(BN(allowedSlippage.toSignificant()).dividedBy(100)))
          .div(BN(10).pow(currency0.decimals))
          .multipliedBy(BN(10).pow(currency1.decimals))
          .toString()
        currentsqrtRatioX96T = toSqrt96(slippagePrice).toFixed(0)
        // currentsqrtRatioX96 = BN(twapPrice.toString())
        //   .minus(allowedSlippage.multiply(twapPrice.toString()).toFixed())
        //   .toFixed(0)
      } else {
        lss = BN(INPUT)
          .multipliedBy(BN(1).div(tokenPrice0))
          .div(BN(10).pow(inputDecimal))
          .multipliedBy(BN(10).pow(outputDecimal))
        lss = BN(lss.toString()).times(sliederLever).toFixed(0)
        const slippagePrice = tokenPrice0
          .multipliedBy(BN(1).minus(BN(allowedSlippage.toSignificant()).dividedBy(100)))
          .div(BN(10).pow(currency0.decimals))
          .multipliedBy(BN(10).pow(currency1.decimals))
          .toString()

        currentsqrtRatioX96T = toSqrt96(slippagePrice).toFixed(0)
        // currentsqrtRatioX96 = BN(twapPrice.toString())
        //   .plus(allowedSlippage.multiply(twapPrice.toString()).toFixed())
        //   .toFixed(0)
      }

      const response = await tradeRouterContract.increasePosition(
        account,
        tradePool,
        colAmount,
        lss,
        istoken0,
        currentsqrtRatioX96T,
        {
          value: currencies[Field.INPUT]?.isNative ? colAmount : 0,
        }
      )
      setAttemptingTxn(false)
      addTransaction(response, {
        type: TransactionType.DEPOSIT_LIQUIDITY_STAKING,
        token0Address: currency0.address,
        token1Address: currency1.address,
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      console.log('increasePosition error', error)
    }
  }, [
    RoxUtilsContract,
    account,
    addTransaction,
    allowedSlippage,
    currency0,
    currency1,
    parsedAmounts,
    poolInfo,
    sliederLever,
    tradePool,
    tradePoolContract,
    tradeRouterContract,
    twapPrice,
  ])

  const createLimitOrder = useCallback(async () => {
    if (!orderbookContract || !poolInfo || !currency0 || !currency1 || !twapPrice) return

    const INPUT = parsedAmounts[Field.INPUT]?.numerator.toString()
    const OUTPUT = parsedAmounts[Field.OUTPUT]?.numerator.toString()

    const feeamount = inputAmount.amount?.numerator.toString()
    if (!INPUT || !RoxUtilsContract || !OUTPUT || !feeamount) return

    setAttemptingTxn(true)

    const istoken0 = compareAddress(currency0.address, parsedAmounts[Field.INPUT]?.currency.wrapped.address)
    let price
    if (state.isToken0) {
      price = toSqrt96(
        BN(limitPrice).div(BN(10).pow(currency0.decimals)).multipliedBy(BN(10).pow(currency1.decimals)).toString()
      ).toFixed(0)
    } else {
      price = toSqrt96(
        BN(1)
          .div(
            BN(limitPrice).div(BN(10).pow(currency0.decimals)).multipliedBy(BN(10).pow(currency1.decimals)).toString()
          )
          .toFixed()
      ).toFixed(0)
    }
    const triggerAboveThreshold = BN(price).gt(twapPrice.toString())

    try {
      const response = await orderbookContract.createIncreaseOrder(
        poolInfo.tradePool,
        BN(INPUT).plus(feeamount).toFixed(0),
        feeamount,
        OUTPUT,
        price,
        istoken0,
        triggerAboveThreshold,
        false
      )
      setAttemptingTxn(false)
      addTransaction(response, {
        type: TransactionType.DEPOSIT_LIQUIDITY_STAKING,
        token0Address: currency0.address,
        token1Address: currency1.address,
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      console.log('increasePosition error', error)
    }
  }, [
    RoxUtilsContract,
    addTransaction,
    currency0,
    currency1,
    inputAmount.amount?.numerator,
    limitPrice,
    orderbookContract,
    parsedAmounts,
    poolInfo,
    state.isToken0,
    twapPrice,
  ])

  const { DynamicApprove, isApproved } = useDynamicApprove(
    [parsedAmounts[Field.INPUT]],
    state.isLimit ? orderbookContract?.address : tradeRouterContract?.address
  )
  const feeAmount = useMemo(() => {
    const input = parsedAmounts[Field.INPUT]?.toExact()
    if (!traderFee || !input || !sliederLever || input == '') return
    const amount = BN(input).times(sliederLever)
    const traderFees = formFeeWei(traderFee.positionFee)
    const fee = amount.times(traderFees).toFixed()
    return fee
  }, [parsedAmounts, sliederLever, traderFee])

  const tradeValue = useMemo(() => {
    const amount = parsedAmounts[Field.OUTPUT]?.toExact()
    const symbol = parsedAmounts[Field.INPUT]?.currency.symbol
    if (!amount || !currentSqrtRatioX96 || !symbol) return
    let amounts
    if (state.isToken0) {
      if (isLong) {
        amounts = BN(amount).div(
          fromSqrt96Wei(currentSqrtRatioX96.toString(), currency0?.decimals, currency1?.decimals)
        )
      } else {
        amounts = BN(amount).times(
          fromSqrt96Wei(currentSqrtRatioX96.toString(), currency0?.decimals, currency1?.decimals)
        )
      }
    } else {
      if (isLong) {
        amounts = BN(amount).times(
          fromSqrt96Wei(currentSqrtRatioX96.toString(), currency0?.decimals, currency1?.decimals)
        )
      } else {
        amounts = BN(amount).div(
          fromSqrt96Wei(currentSqrtRatioX96.toString(), currency0?.decimals, currency1?.decimals)
        )
      }
    }

    if (amounts.isNaN()) {
      return (
        <>
          {countZeros(0)} {symbol}
        </>
      )
    }

    return (
      <>
        {countZeros(amounts.toFixed())} {symbol}
      </>
    )
  }, [currency0?.decimals, currency1?.decimals, currentSqrtRatioX96, isLong, parsedAmounts, state.isToken0])

  const inputTradevalue = useMemo(() => {
    const amount = parsedAmounts[Field.INPUT]?.toExact()
    const symbol = parsedAmounts[Field.OUTPUT]?.currency.symbol

    if (!amount || !currentSqrtRatioX96 || !symbol) return
    let amounts
    if (state.isToken0) {
      if (isLong) {
        amounts = BN(amount).times(
          fromSqrt96Wei(currentSqrtRatioX96.toString(), currency0?.decimals, currency1?.decimals)
        )
      } else {
        amounts = BN(amount).div(
          fromSqrt96Wei(currentSqrtRatioX96.toString(), currency0?.decimals, currency1?.decimals)
        )
      }
    } else {
      if (isLong) {
        amounts = BN(amount).div(
          fromSqrt96Wei(currentSqrtRatioX96.toString(), currency0?.decimals, currency1?.decimals)
        )
      } else {
        amounts = BN(amount).times(
          fromSqrt96Wei(currentSqrtRatioX96.toString(), currency0?.decimals, currency1?.decimals)
        )
      }
    }
    if (amounts.isNaN()) {
      return (
        <>
          {countZeros(0)} {symbol}
        </>
      )
    }

    return (
      <>
        {countZeros(amounts.toFixed())} {symbol}
      </>
    )
  }, [currency0?.decimals, currency1?.decimals, currentSqrtRatioX96, isLong, parsedAmounts, state.isToken0])

  const handleOutputSelect = useCallback(
    (outputCurrency: Currency) => {
      onCurrencySelection(Field.OUTPUT, outputCurrency)
      onCurrencyChange?.({
        [Field.INPUT]: state[Field.INPUT],
        [Field.OUTPUT]: {
          currencyId: getSwapCurrencyId(outputCurrency),
        },
      })
    },
    [onCurrencyChange, onCurrencySelection, state]
  )

  const handleInputSelect = useCallback(
    (inputCurrency: Currency) => {
      onCurrencySelection(Field.INPUT, inputCurrency)
      onCurrencyChange?.({
        [Field.INPUT]: {
          currencyId: getSwapCurrencyId(inputCurrency),
        },
        [Field.OUTPUT]: state[Field.OUTPUT],
      })
    },
    [onCurrencyChange, onCurrencySelection, state]
  )

  return (
    <>
      <RightWrapper>
        <Tab curr={navTab} handlerChange={handlerChange} bg={trackBg} />
        <Row mt="8px">
          <SwapDetails price={tokenRatio} showInverted={navTab} limitPrice={limitPrice} setLimitPrice={setLimitPrice} />
        </Row>
        <InputSwapSection>
          <SwapCurrencyInputPanel
            label={<Trans>From</Trans>}
            disabled={disableTokenInputs}
            value={formattedAmounts[Field.INPUT]}
            showMaxButton={false}
            currency={currencies[Field.INPUT] ?? null}
            onUserInput={handleTypeInput}
            onMaxTab={handleTypeInput}
            onMax={handleMaxInput}
            // fiatValue={showFiatValueInput ? fiatValueInput : undefined}
            tradeValue={inputTradevalue}
            onCurrencySelect={
              currencies[Field.INPUT]?.symbol == 'ETH' || currencies[Field.INPUT]?.symbol == 'WETH'
                ? handleInputSelect
                : undefined
            }
            otherCurrency={currencies[Field.OUTPUT]}
            showCommonBases
            id="trade-input"
            loading={false}
            showMaxTab={true}
            showSlider={true}
            showPerpEth={
              currencies[Field.INPUT]?.symbol == 'ETH' || currencies[Field.INPUT]?.symbol == 'WETH' ? true : false
            }
          />
        </InputSwapSection>
        <OutputSwapSection>
          <SwapCurrencyInputPanel
            value={formattedAmounts[Field.OUTPUT]}
            disabled={false}
            onUserInput={handleTypeOutput}
            label={independentField === Field.INPUT && !showWrap ? <Trans>To (at least)</Trans> : <Trans>To</Trans>}
            showMaxButton={false}
            hideBalance={false}
            renderBalance={() => {
              return symbol
            }}
            // fiatValue={showFiatValueOutput ? fiatValueOutput : undefined}
            tradeValue={tradeValue}
            priceImpact={stablecoinPriceImpact}
            currency={currencies[Field.OUTPUT] ?? null}
            onCurrencySelect={
              currencies[Field.OUTPUT]?.symbol == 'ETH' || currencies[Field.OUTPUT]?.symbol == 'WETH'
                ? handleOutputSelect
                : undefined
            }
            otherCurrency={currencies[Field.INPUT]}
            showCommonBases
            id="trade-output"
            loading={false}
            showPerpEth={
              currencies[Field.OUTPUT]?.symbol == 'ETH' || currencies[Field.OUTPUT]?.symbol == 'WETH' ? true : false
            }
          />
        </OutputSwapSection>

        {state.isLimit && (
          <OutputSwapSection>
            <SwapCurrencyInputPanel
              value={inputAmount.value}
              disabled={false}
              onUserInput={handleUserInput}
              label={independentField === Field.INPUT && !showWrap ? <Trans>To (at least)</Trans> : <Trans>To</Trans>}
              showMaxButton={false}
              hideBalance={false}
              // renderBalance={() => {
              //   return symbol
              // }}
              // fiatValue={showFiatValueOutput ? fiatValueOutput : undefined}
              // tradeValue={tradeValue}
              // priceImpact={stablecoinPriceImpact}
              tradeValue="Execution Fees"
              currency={currencies[Field.INPUT] ?? null}
              // onCurrencySelect={handleOutputSelect}
              otherCurrency={currencies[Field.INPUT]}
              showCommonBases
              id="trade-output-fee"
              loading={false}
            />
          </OutputSwapSection>
        )}
        {/* <RowBetween mt="8px">
          <ThemedText.TextSecondary fontSize={14}>Profits In</ThemedText.TextSecondary>
          <Row width="auto" align="center" className="pointer">
            <ThemedText.TextPrimary fontWeight={700} mr="8px">
              {isLong ? currency0?.symbol : currency1?.symbol}
            </ThemedText.TextPrimary>
            <StyledDropDown />
          </Row>
        </RowBetween> */}
        {/* <RowBetween mt="8px">
          <ThemedText.TextSecondary fontSize={14}>Leverage</ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontWeight={700}>{sliederLever}x</ThemedText.TextPrimary>
        </RowBetween> */}
        {state.isLimit ? (
          <>
            <RowBetween mt="8px">
              <ThemedText.TextSecondary fontSize={14}>
                <Trans>Entry Price</Trans>
              </ThemedText.TextSecondary>
              <Row justify="flex-end" flex={1} gap="4px">
                <ThemedText.TextPrimary fontWeight={700}>{countZeros(limitPrice)}</ThemedText.TextPrimary>
                <ThemedText.TextSecondary fontSize={12}>{symbol}</ThemedText.TextSecondary>
              </Row>
            </RowBetween>
            <RowBetween mt="8px">
              <ThemedText.TextSecondary fontSize={14}>
                <Trans>Liq. Price</Trans>
              </ThemedText.TextSecondary>
              <Row justify="flex-end" flex={1} alignContent="center" gap="4px">
                <ThemedText.TextPrimary fontWeight={700}>0</ThemedText.TextPrimary>
                <ThemedText.TextSecondary fontSize={12}>{symbol}</ThemedText.TextSecondary>
              </Row>
            </RowBetween>
            <RowBetween mt="8px">
              <ThemedText.TextSecondary fontSize={14}>
                <Trans>Spread</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={700}>0%</ThemedText.TextPrimary>
            </RowBetween>
            <RowBetween mt="8px">
              <ThemedText.TextSecondary fontSize={14}>
                <Trans>Fees</Trans>
              </ThemedText.TextSecondary>
              <Row justify="flex-end" flex={1} gap="4px">
                <ThemedText.TextSecondary fontSize={12}>
                  <FeesDetails
                    fees={feeAmount}
                    ExecutionFees={inputAmount.value}
                    token={currencies[Field.INPUT]?.symbol}
                    positionFee={traderFee?.positionFee}
                    allFees={BN(feeAmount || 0)
                      .plus(inputAmount.value || 0)
                      .toFixed()}
                  />
                </ThemedText.TextSecondary>
              </Row>
            </RowBetween>
          </>
        ) : (
          <>
            <RowBetween mt="8px">
              <ThemedText.TextSecondary fontSize={14}>
                <Trans>Entry Price</Trans>
              </ThemedText.TextSecondary>
              <Row justify="flex-end" flex={1} gap="4px">
                <ThemedText.TextPrimary fontWeight={700}>
                  {increaseLoading ? (
                    <LoadingDataBubble />
                  ) : estimateIncrease && estimateIncrease.prevOpenPrice && estimateIncrease.prevOpenPrice != '0' ? (
                    <Row>
                      <ThemedText.TextSecondary fontSize={12}>
                        {countZeros(estimateIncrease.prevOpenPrice)}
                      </ThemedText.TextSecondary>
                      &nbsp;→ {countZeros(estimateIncrease.sqrt96)}
                    </Row>
                  ) : (
                    countZeros(estimateIncrease?.sqrt96 || '0')
                  )}
                </ThemedText.TextPrimary>
                <ThemedText.TextSecondary fontSize={12}>{symbol}</ThemedText.TextSecondary>
              </Row>
            </RowBetween>
            <RowBetween mt="8px">
              <ThemedText.TextSecondary fontSize={14}>
                <Trans>Liq. Price</Trans>
              </ThemedText.TextSecondary>
              <Row justify="flex-end" flex={1} alignContent="center" gap="4px">
                <ThemedText.TextPrimary fontWeight={700}>
                  {increaseLoading ? (
                    <LoadingDataBubble />
                  ) : state.isLimit ? (
                    countZeros(limitPrice)
                  ) : estimateIncrease && estimateIncrease.prevLiqPrice && estimateIncrease.prevLiqPrice != '0' ? (
                    <Row>
                      <ThemedText.TextSecondary fontSize={12}>
                        {countZeros(estimateIncrease.prevLiqPrice)}
                      </ThemedText.TextSecondary>
                      &nbsp;→ {countZeros(estimateIncrease.liqPrice)}
                    </Row>
                  ) : (
                    countZeros(estimateIncrease?.liqPrice || '0')
                  )}
                </ThemedText.TextPrimary>
                <ThemedText.TextSecondary fontSize={12}>{symbol}</ThemedText.TextSecondary>
              </Row>
            </RowBetween>
            <RowBetween mt="8px">
              <ThemedText.TextSecondary fontSize={14}>
                <Trans>Spread</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={700}>
                {increaseLoading ? <LoadingDataBubble /> : countZeros(estimateIncrease?.spread || '0')}%
              </ThemedText.TextPrimary>
            </RowBetween>
            <RowBetween mt="8px">
              <ThemedText.TextSecondary fontSize={14}>
                <Trans>Fees</Trans>
              </ThemedText.TextSecondary>
              <Row justify="flex-end" flex={1} gap="4px">
                <ThemedText.TextPrimary fontWeight={700}>
                  {countZeros(feeAmount)} {currencies[Field.INPUT]?.symbol}
                </ThemedText.TextPrimary>
                <ThemedText.TextSecondary fontSize={12}>
                  (
                  {formFeeWei(traderFee?.positionFee || BigNumber.from(0))
                    .times(100)
                    .toFixed(2)}
                  %)
                </ThemedText.TextSecondary>
              </Row>
            </RowBetween>
          </>
        )}

        <RowBetween mt="8px">
          <ThemedText.TextSecondary fontSize={14}>
            <Trans>Available Liquidity</Trans>
          </ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontWeight={700}>
            {poolLoading ? (
              <LoadingDataBubble />
            ) : (
              <>
                {formatAmount(countZeros(maxLiquidity?.toExact()), 2, true)} {maxLiquidity?.currency.symbol}
              </>
            )}
          </ThemedText.TextPrimary>
        </RowBetween>
        <RowBetween mt="8px">
          <ThemedText.TextSecondary fontSize={14}>
            <Trans>Max Slippage</Trans>
          </ThemedText.TextSecondary>
          <Row width="auto" align="center" className="pointer">
            <ThemedText.TextPrimary fontWeight={700} mr="8px">
              {slippageTolerance}%
            </ThemedText.TextPrimary>
            <SettingsTab autoSlippage={autoSlippage} chainId={chainId} trade={undefined} />
          </Row>
        </RowBetween>

        <Row>
          <DynamicApprove />
        </Row>
        <Row mt="8px ">
          {address ? (
            <ButtonConfirmed
              id="trade-button"
              disabled={!isApproved || !!swapInputError}
              onClick={() => setShowConfirm(true)}
            >
              {swapInputError ? swapInputError : isLong ? 'Long' : 'Short'}
            </ButtonConfirmed>
          ) : (
            <ButtonConfirmed id="connect-button" onClick={() => openConnectModal && openConnectModal()}>
              <Trans>Connect Wallet</Trans>
            </ButtonConfirmed>
          )}
        </Row>
      </RightWrapper>
      <TradeConfirmationModal
        inputCurrency={parsedAmounts[Field.INPUT]}
        outputCurrency={parsedAmounts[Field.OUTPUT]}
        isOpen={showConfirm}
        liqPrice={estimateIncrease?.liqPrice}
        fee={feeAmount || '0'}
        positionFee={traderFee?.positionFee}
        spread={estimateIncrease?.spread || '0'}
        entryPrice={estimateIncrease?.sqrt96}
        prevOpenPrice={estimateIncrease?.prevOpenPrice}
        prevLiqPrice={estimateIncrease?.prevLiqPrice}
        onDismiss={handleDismissConfirmation}
        onConfirm={handelOpenType}
        attemptingTxn={attemptingTxn}
        txHash={txHash}
        txError={txError}
        isLimit={state.isLimit}
        limitEntryPrice={limitPrice}
        limitfee={inputAmount.value}
        pendingText={pendingText}
        inputtrade={inputTradevalue}
        outputtrade={tradeValue}
      />
    </>
  )
}
