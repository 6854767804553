import { atom, useAtom } from 'jotai'
import { atomWithReset } from 'jotai/utils'
import { useCallback } from 'react'

export enum LeaderboardSortMethod {
  POOLS = 'Pools',
  TVL = 'TVL',
  VOLUME = 'Volume(24h)',
  TOTAL_REWARDS = 'Total Rewards',
  MY_VOLUME = 'My Volume(24h)',
  EARN = 'Earn',
  BRIBES = 'Bribes',
}

export const LeaderboardfilterStringAtom = atomWithReset<string>('')
export const LeaderboardsortMethodAtom = atom<LeaderboardSortMethod>(LeaderboardSortMethod.TVL)
export const LeaderboardsortAscendingAtom = atom<boolean>(false)

/* keep track of sort category for token table */
export function useLeaderboardSetSortMethod(newSortMethod: LeaderboardSortMethod) {
  const [sortMethod, setSortMethod] = useAtom(LeaderboardsortMethodAtom)
  const [sortAscending, setSortAscending] = useAtom(LeaderboardsortAscendingAtom)

  return useCallback(() => {
    if (sortMethod === newSortMethod) {
      setSortAscending(!sortAscending)
    } else {
      setSortMethod(newSortMethod)
      setSortAscending(false)
    }
  }, [sortMethod, setSortMethod, setSortAscending, sortAscending, newSortMethod])
}
