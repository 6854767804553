export const teamGoals: any = {
  0: {
    lv: 0,
    point: 0,
    multiplier: 1,
    maxReferral: 5,
  },
  1: {
    lv: 1,
    point: 3000,
    multiplier: 1.2,
    maxReferral: 15,
  },
  2: {
    lv: 2,
    point: 12000,
    multiplier: 1.4,
    maxReferral: 25,
  },
  3: {
    lv: 3,
    point: 70000,
    multiplier: 1.7,
    maxReferral: 35,
  },
  4: {
    lv: 4,
    point: 500000,
    multiplier: 2.0,
    maxReferral: 45,
  },
  5: {
    lv: 5,
    point: 2000000,
    multiplier: 2.5,
    maxReferral: '99999999+',
  },
}
export const teamGoalsArr: any = [
  {
    lv: 0,
    point: 0,
    multiplier: 1,
    maxReferral: 5,
  },
  {
    lv: 1,
    point: 3000,
    multiplier: 1.2,
    maxReferral: 15,
  },
  {
    lv: 2,
    point: 12000,
    multiplier: 1.4,
    maxReferral: 25,
  },
  {
    lv: 3,
    point: 70000,
    multiplier: 1.7,
    maxReferral: 35,
  },
  {
    lv: 4,
    point: 500000,
    multiplier: 2.0,
    maxReferral: 45,
  },
  {
    lv: 5,
    point: 2000000,
    multiplier: 2.5,
    maxReferral: '99999999+',
  },
]
