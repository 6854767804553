import { Trans } from '@lingui/macro'
import { CurrencyAmount, Percent, Token } from '@uniswap/sdk-core'
import votingRewardABi from 'abis/votingReward.json'
import FLATBigNumber from 'bignumber.js'
import { ClaimButton, Pending } from 'components/Button'
import Column from 'components/Column'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { IconLoadingBubble, LoadingBubble, MediumLoadingBubble } from 'components/Loading'
import Row from 'components/Row'
import { StyledTableRow } from 'components/Table'
import { NoLiquityTips } from 'components/Tips'
import { ToastError } from 'components/Toast'
import { MAX_WIDTH_MEDIA_BREAKPOINT, SMALL_MEDIA_BREAKPOINT } from 'components/Tokens/constants'
import { useActiveChainId, useEthersSigner } from 'connection/useActiveChainId'
import { useToken } from 'hooks/Tokens'
import { useVotingReward } from 'hooks/useContract'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { TextLeftCell, TextRightCell } from 'pages/Lock/LockRow'
import { ChangeEvent, CSSProperties, ForwardedRef, forwardRef, ReactNode, useCallback, useMemo, useState } from 'react'
import { Box } from 'rebass'
import { useVoteCurrentId } from 'state/http/hooks'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled, { css } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { fromWei } from 'utils/bn'
import { countZeros } from 'utils/countZeros'
import { getCurrRound, getWeeklyId } from 'utils/CurrRound'
import { formatAmount } from 'utils/formatAmout'
import { formatCurrencyAmount, NumberType } from 'utils/formatCurrency'
import { handlerError } from 'utils/formatError'
import { getContract } from 'utils/getContract'
import { unwrappedToken } from 'utils/unwrappedToken'

import { ReactComponent as AddIcon } from '../../assets/imgs/add.svg'
import AddBribesModal from './AddBribesModal'
import HeaderCell from './HeaderCell'
import { VoteSortMethod } from './state'
import { rewardPorps, VoteDataProps } from './types'

const StyledVoteRow = styled(StyledTableRow)<{
  first?: boolean
  last?: boolean
  $loading?: boolean
}>`
  align-items: flex-start;
  grid-template-columns: 200px 1.5fr 1.3fr 1.2fr 1.2fr 1.5fr 1.2fr 1.5fr 1.5fr;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  min-width: 390px;
  padding: 0;
  margin: 0;
  ${({ first, last }) => css`
    /* height: ${first || last ? '72px' : '64px'}; */
  `}
  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => css`background-color ${duration.medium} ${timing.ease}`};
  transition-duration: ${({ theme }) => theme.transition.duration.fast};

  &:hover {
    ${({ $loading, theme }) =>
      !$loading &&
      css`
        background-color: ${theme.hoverDefault};
      `}
    ${({ last }) =>
      last &&
      css`
        border-radius: 0px 0px 8px 8px;
      `}
  }
  .bg {
    height: 100%;
    ${({ theme }) =>
      css`
        background-color: ${theme.hoverDefault};
      `}
  }
`

export const VoteBox = styled(Box)`
  margin-top: 4px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 4px;
  padding: 4px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.voteInputBg};
  border: 1px solid ${({ theme }) => theme.voteInputBorder};
`

export const VoteInput = styled.input<{ error?: boolean }>`
  outline: none;
  border: none;
  flex: 1 1 auto;
  background-color: transparent;
  border-radius: 8px;
  transition: color 300ms ${({ error }) => (error ? 'step-end' : 'step-start')};
  color: ${({ theme }) => theme.textPrimary};
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 500;
  text-align: right;
  width: 100%;
  height: 100%;
  ::placeholder {
    color: ${({ theme }) => theme.deprecated_text4};
  }
  padding: 0px;
  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.deprecated_text4};
  }
`

export const MaxBtn = styled(Box)`
  width: 58px;
  height: 32px;
  padding: 6px 16px;
  font-size: 14px;
  font-weight: 700;
  word-break: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  background: ${({ theme }) => theme.maxBtnBackground};
  cursor: pointer;
`

const StyledTrRow = styled(StyledVoteRow)``

const StyledHeaderRow = styled(StyledVoteRow)`
  height: 40px;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.textPrimary};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  &:hover {
    background-color: transparent;
  }

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    justify-content: space-between;
  }
`

/* Token Row: skeleton row component */
function VoteRow({
  header,
  pool,
  tvl,
  vApr,
  fees,
  bribes,
  totalRewards,
  totalVotes,
  myVote,
  earn,
  ...rest
}: {
  first?: boolean
  header: boolean
  $loading?: boolean
  fees: ReactNode
  tvl?: ReactNode
  vApr: ReactNode
  pool: ReactNode
  bribes: ReactNode
  totalRewards: ReactNode
  totalVotes: ReactNode
  myVote: ReactNode
  earn: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowHeaderCells = (
    <>
      <TextLeftCell data-testid="pool-cell">{pool}</TextLeftCell>
      <TextRightCell data-testid="tvl-cell">{tvl}</TextRightCell>
      <TextLeftCell data-testid="vApr-cell">{vApr}</TextLeftCell>
      <TextRightCell data-testid="fees-cell">{fees}</TextRightCell>
      <TextRightCell data-testid="bribes-cell">{bribes}</TextRightCell>
      <TextRightCell data-testid="total-rewards-cell">{totalRewards}</TextRightCell>
      <TextRightCell data-testid="total-votes-cell">{totalVotes}</TextRightCell>
      <TextRightCell data-testid="my-vote-cell" className="bg">
        {myVote}
      </TextRightCell>
      <TextRightCell data-testid="earn-cell" className="bg">
        {earn}
      </TextRightCell>
    </>
  )
  const rowTrCells = (
    <>
      <TextLeftCell data-testid="pool-cell">
        <Box p="16px 0" width="100%" height="100%">
          {pool}
        </Box>
      </TextLeftCell>
      <TextRightCell data-testid="tvl-cell">
        <Box p="16px 0" width="100%" height="100%">
          {tvl}
        </Box>
      </TextRightCell>
      <TextLeftCell data-testid="vApr-cell">
        <Box p="16px 0" width="100%" height="100%">
          {vApr}
        </Box>
      </TextLeftCell>
      <TextRightCell data-testid="fees-cell">
        <Box p="16px 0" width="100%" height="100%">
          {fees}
        </Box>
      </TextRightCell>
      <TextRightCell data-testid="bribes-cell">
        <Box p="16px 0" width="100%" height="100%">
          {bribes}
        </Box>
      </TextRightCell>
      <TextRightCell data-testid="total-rewards-cell">
        <Box p="16px 0" width="100%" height="100%">
          {totalRewards}
        </Box>
      </TextRightCell>
      <TextRightCell data-testid="total-votes-cell">
        <Box p="16px 0" width="100%" height="100%">
          {totalVotes}
        </Box>
      </TextRightCell>
      <TextRightCell data-testid="my-vote-cell" className="bg">
        <Box p="16px 0" width="100%" height="100%">
          {myVote}
        </Box>
      </TextRightCell>
      <TextRightCell data-testid="earn-cell" className="bg">
        <Box p="16px 0" width="100%" height="100%">
          {earn}
        </Box>
      </TextRightCell>
    </>
  )
  if (header) return <StyledHeaderRow data-testid="vote-header-row">{rowHeaderCells}</StyledHeaderRow>
  return <StyledTrRow {...rest}>{rowTrCells}</StyledTrRow>
}

/* Header Row: top header row component for table */
export function HeaderRow() {
  return (
    <VoteRow
      header={true}
      pool={<Trans>{VoteSortMethod.POOL}</Trans>}
      tvl={
        <Row justify="end">
          <HeaderCell category={VoteSortMethod.TVL} />
        </Row>
      }
      vApr={
        <Trans>
          <HeaderCell category={VoteSortMethod.VAPR} />
        </Trans>
      }
      fees={
        <Row justify="end">
          <HeaderCell category={VoteSortMethod.FEES} />
        </Row>
      }
      bribes={
        <Row justify="end">
          <HeaderCell category={VoteSortMethod.BRIBES} />
        </Row>
      }
      totalRewards={
        <Row justify="end">
          <HeaderCell category={VoteSortMethod.TOTAL_REWARDS} />
        </Row>
      }
      totalVotes={
        <Row justify="end">
          <HeaderCell category={VoteSortMethod.TOTAL_VOTES} />
        </Row>
      }
      myVote={
        <Row justify="end">
          <HeaderCell category={VoteSortMethod.MY_VOTE} />
        </Row>
      }
      earn={
        <Row justify="end">
          <HeaderCell category={VoteSortMethod.EARN} />
        </Row>
      }
    />
  )
}
/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <VoteRow
      header={false}
      $loading
      pool={
        <Row gap="xs">
          <IconLoadingBubble width="40px" height="40px" />
          <MediumLoadingBubble />
        </Row>
      }
      tvl={
        <Column align="end" width="100%" gap="xs">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
          <MediumLoadingBubble />
        </Column>
      }
      vApr={
        <Row justify="flex-end" gap="xs">
          <LoadingBubble />
        </Row>
      }
      fees={
        <Column align="end" width="100%" gap="xs">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
          <MediumLoadingBubble />
        </Column>
      }
      bribes={
        <Column align="end" width="100%" gap="xs">
          <MediumLoadingBubble />
          <AddIcon />
        </Column>
      }
      totalRewards={
        <Column align="end" width="100%" gap="xs">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
          <MediumLoadingBubble />
        </Column>
      }
      totalVotes={
        <Column align="end" width="100%" gap="xs">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
        </Column>
      }
      myVote={
        <Column align="end" width="100%" gap="xs">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
          <MediumLoadingBubble />
        </Column>
      }
      earn={
        <Column align="end" width="100%" gap="xs">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
          <ClaimButton>Claim</ClaimButton>
        </Column>
      }
      {...props}
    />
  )
}

export interface LoadedRowProps {
  voteListIndex: number
  voteListLength: number
  vote: VoteDataProps
  inputRef: any
  inputValue: {
    [key: string]: {
      value: string
      bn: FLATBigNumber
      data: VoteDataProps
    }
  }
  onChange: (val: any, data: any) => void
}

export const TotalRewardsItem = ({ rewardAddr }: { rewardAddr: rewardPorps }) => {
  const { chainId } = useActiveChainId()
  const currenty = useMemo(
    () =>
      chainId
        ? new Token(
            chainId,
            rewardAddr.tokenInfo.id,
            Number(rewardAddr.tokenInfo.decimals),
            rewardAddr.tokenInfo.symbol,
            rewardAddr.tokenInfo.name
          )
        : undefined,
    [
      chainId,
      rewardAddr.tokenInfo.decimals,
      rewardAddr.tokenInfo.id,
      rewardAddr.tokenInfo.name,
      rewardAddr.tokenInfo.symbol,
    ]
  )

  const tokenInfo = useMemo(() => {
    if (!currenty) return
    return fromWei(rewardAddr?.rewardAmount || 0, currenty.decimals)
  }, [currenty, rewardAddr.rewardAmount])
  return (
    <Box alignItems="center" display="flex" justifyContent="center">
      <ThemedText.TextSecondary fontSize={14}>
        {countZeros(formatAmount(tokenInfo, 2, true))} {currenty?.symbol}
      </ThemedText.TextSecondary>
      {rewardAddr.tokenInfo?.shit && <NoLiquityTips />}
    </Box>
  )
}

export const TotalVoteEarnsItem = ({ rewardAddr, poolAddr }: { rewardAddr: rewardPorps; poolAddr: string }) => {
  const { chainId, account } = useActiveChainId()
  const currRound = getCurrRound()
  const currenty = useMemo(
    () =>
      chainId
        ? new Token(
            chainId,
            rewardAddr.tokenInfo.id,
            Number(rewardAddr.tokenInfo.decimals),
            rewardAddr.tokenInfo.symbol,
            rewardAddr.tokenInfo.name
          )
        : undefined,
    [
      chainId,
      rewardAddr.tokenInfo.decimals,
      rewardAddr.tokenInfo.id,
      rewardAddr.tokenInfo.name,
      rewardAddr.tokenInfo.symbol,
    ]
  )
  const tokenContract = useVotingReward(poolAddr)
  const { result } = useSingleCallResult(tokenContract, 'earned', [currenty?.address, account, currRound])

  const tokenInfo = useMemo(() => {
    if (!result || !currenty) return
    return CurrencyAmount.fromRawAmount(currenty, result[0])
  }, [currenty, result])
  return (
    <ThemedText.TextRewards>
      {formatAmount(tokenInfo?.toSignificant(), 2, true)} {currenty?.symbol}
    </ThemedText.TextRewards>
  )
}

/* Loaded State: row component with token information */
export const LoadedRow = forwardRef((props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { voteListIndex, voteListLength, vote, inputRef, onChange, inputValue } = props

  const [showAddBribes, setShowAddBribes] = useState(false)
  const handleDismissAddBribes = () => {
    setShowAddBribes(false)
  }
  const singerProvider = useEthersSigner()
  const addTransaction = useTransactionAdder()
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [txError, setTxError] = useState<string>('')
  const { account } = useActiveChainId()
  const currRound = getCurrRound()
  const handleAmountChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value, vote)
    },
    [onChange, vote]
  )
  const onClaim = useCallback(
    async (data: any) => {
      setAttemptingTxn(true)
      const contract = getContract(data.votingRewardAddress, votingRewardABi.abi, singerProvider)
      try {
        const response = await contract.getReward(account, currRound)
        setAttemptingTxn(false)
        addTransaction(response, {
          type: TransactionType.CLAIM_REWARDS,
          token0Address: '',
          token1Address: '',
        })
        setTxHash(response.hash)
      } catch (error) {
        setAttemptingTxn(false)
        setTxError(handlerError(error))
        ToastError(handlerError(error))
        console.log('create Lock error', error)
      }
    },
    [account, addTransaction, currRound, singerProvider]
  )

  const epochNum = useVoteCurrentId()
  const currentEpochId = getWeeklyId()
  const isCurrEpoch = useMemo(() => {
    return currentEpochId === epochNum
  }, [currentEpochId, epochNum])

  const token0 = useToken(vote.token0.id)
  const token1 = useToken(vote.token1.id)
  const currency0 = token0 ? unwrappedToken(token0) : undefined
  const currency1 = token1 ? unwrappedToken(token1) : undefined

  return (
    <>
      <div ref={ref} data-testid={`vote-table-row-${vote.lpAddr}`}>
        <VoteRow
          header={false}
          pool={
            <Row>
              <DoubleCurrencyLogo currency0={currency1} currency1={currency0} size={40} margin />
              <Column gap="xs" align="flex-start" ml="16px">
                <ThemedText.TextPrimary fontWeight={700} fontSize={16}>
                  {vote.token0.symbol}-{vote.token1.symbol}
                </ThemedText.TextPrimary>
                <ThemedText.TextPrimary fontWeight={700} fontSize={16}>
                  {new Percent(vote.poolFee, 1_000_000).toSignificant()}%
                </ThemedText.TextPrimary>
              </Column>
            </Row>
          }
          tvl={
            <Column align="end" width="100%" gap="xs">
              <ThemedText.TextPrimary fontWeight={700}>${formatAmount(vote.tvl, 2, true)}</ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>
                {formatCurrencyAmount(vote.liqvidityToken0, NumberType.FiatTokenStats2)} {vote.token0.symbol}
              </ThemedText.TextSecondary>
              <ThemedText.TextSecondary fontSize={14}>
                {formatCurrencyAmount(vote.liqvidityToken1, NumberType.FiatTokenStats2)} {vote.token1.symbol}
              </ThemedText.TextSecondary>
            </Column>
          }
          vApr={
            <Row justify="flex-end" gap="xs">
              <ThemedText.TextPrimary fontWeight={700}>
                {formatAmount(Number(vote.vAPR) * 100, 2, true)}%
              </ThemedText.TextPrimary>
            </Row>
          }
          fees={
            <Column align="end" width="100%" gap="xs">
              <ThemedText.TextPrimary fontWeight={700}>
                ${formatAmount(vote.votetotalFeeRewardsUSD, 2, true)}
              </ThemedText.TextPrimary>
              {vote.feeReward?.map((item: any, index: number) => {
                return <TotalRewardsItem key={'fees' + index} rewardAddr={item} />
              })}
            </Column>
          }
          bribes={
            <Column align="end" width="100%" gap="xs">
              <ThemedText.TextPrimary fontWeight={700}>
                ${formatAmount(vote.votetotalBribeRewardsUSD, 2, true)}
              </ThemedText.TextPrimary>
              {vote.bribeReward?.map((item: any, index: number) => {
                return <TotalRewardsItem key={'bribeReward' + index} rewardAddr={item} />
              })}
              {isCurrEpoch && (
                <AddIcon style={{ cursor: 'pointer', marginTop: '8px' }} onClick={() => setShowAddBribes(true)} />
              )}
            </Column>
          }
          totalRewards={
            <Column align="end" width="100%" gap="xs">
              <ThemedText.TextPrimary fontWeight={700}>
                ${formatAmount(vote.votetotalRewardsUSD, 2, true)}
              </ThemedText.TextPrimary>
              {vote.rewardsList && vote.rewardsList.length > 0 ? (
                vote.rewardsList.map((item, index: number) => {
                  return <TotalRewardsItem key={'vote-rewardItem' + index} rewardAddr={item} />
                })
              ) : (
                <ThemedText.TextSecondary fontSize={14}>--</ThemedText.TextSecondary>
              )}
            </Column>
          }
          totalVotes={
            <Column align="end" width="100%" gap="xs">
              <ThemedText.TextPrimary fontWeight={700}>
                {formatAmount(Number(vote?.weeklyVotedTotal) / Math.pow(10, 18), 2, true)}
              </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>{vote.poolWeightRatio?.toFixed(2, 1)}%</ThemedText.TextSecondary>
            </Column>
          }
          myVote={
            <Column align="end" width="100%" gap="xs">
              <ThemedText.TextPrimary fontWeight={700}>
                {formatAmount(Number(vote.epochVote) / Math.pow(10, 18), 2, true)}
              </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>
                {Number(vote.epochVoteTotal) == 0 || Number(vote.epochVote) == 0
                  ? 0
                  : ((Number(vote.epochVote) / Number(vote.epochVoteTotal)) * 100).toFixed(2)}
                %
              </ThemedText.TextSecondary>
              {isCurrEpoch && (
                <VoteBox>
                  <VoteInput
                    type="number"
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    placeholder=""
                    key={vote.lpAddr}
                    id={'VoteInput' + vote.lpAddr}
                    onChange={handleAmountChange}
                    value={inputValue[vote.lpAddr]?.value}
                    ref={inputRef}
                  />
                  <ThemedText.TextPrimary ml="4px" mr="4px">
                    %
                  </ThemedText.TextPrimary>
                  <MaxBtn>
                    <div
                      className="active-gradient-text"
                      onClick={() => {
                        onChange(100, vote)
                      }}
                    >
                      Max
                    </div>
                  </MaxBtn>
                </VoteBox>
              )}
            </Column>
          }
          earn={
            <Column align="end" width="100%" gap="xs">
              {vote.rewardsList && vote.rewardsList?.length > 0 ? (
                vote.rewardsList?.map((item, index: number) => {
                  return (
                    <TotalVoteEarnsItem
                      key={'earn-vote' + index}
                      rewardAddr={item}
                      poolAddr={vote.votingRewardAddress}
                    />
                  )
                })
              ) : (
                <ThemedText.TextRewards>--</ThemedText.TextRewards>
              )}
              <Row justify="end">
                <ClaimButton disabled={attemptingTxn} onClick={() => onClaim(vote)}>
                  {attemptingTxn ? <Pending /> : 'Claim'}
                </ClaimButton>
              </Row>
            </Column>
          }
          first={voteListIndex === 0}
          last={voteListIndex === voteListLength - 1}
        />
      </div>
      <AddBribesModal brigeType="VOTE" isOpen={showAddBribes} details={vote} onDismiss={handleDismissAddBribes} />
    </>
  )
})

LoadedRow.displayName = 'LoadedRow'
