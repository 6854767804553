import { Trans } from '@lingui/macro'
import { ReactComponent as LongIcon } from 'assets/svg/swap/long.svg'
import { ReactComponent as ShortIcon } from 'assets/svg/swap/short.svg'
import { AutoColumn } from 'components/Column'
import {
  StyledPositionCloseIcon,
  StyledPositionEditIcon,
  StyledPositionEntryIcon,
  StyledPositionLiqIcon,
  StyledPriceChangeIcon,
} from 'components/Icons/StyledIcon'
import { IconLoadingBubble, LoadingBubble, MediumLoadingBubble } from 'components/Loading'
import Row from 'components/Row'
import { useCurrency } from 'hooks/Tokens'
import { TextLeftCell, TextRightCell } from 'pages/Lock/LockRow'
import { usePositonDetail } from 'pages/Trade/Hooks'
import { TradePositionProps } from 'pages/Trade/types'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode, useMemo } from 'react'
import { Box } from 'rebass'
import { useTradeSWAPState } from 'state/positionSwap/hooks'
import { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { BN, formFeeWei, fromSqrt96Wei, fromWei } from 'utils/bn'
import { countZeros } from 'utils/countZeros'

import { StyledHeaderRow, StyledRow } from '../styles'
import { PositionMarkPrice } from './PositionMarkPrice'
import { PositionNetValue } from './PositionNetValue'
import { PositionSize } from './PositionSize'
import { TokenSortMethod } from './state'

/* Token Row: skeleton row component */
function PositionRow({
  header,
  Position,
  Size,
  NetValue,
  // Collateral,
  MarkPrice,
  EntryPrice,
  // LiqPrice,
  Acitons,
  ...rest
}: {
  first?: boolean
  header: boolean
  $loading?: boolean
  Position: ReactNode
  Size: ReactNode
  NetValue: ReactNode
  // Collateral: ReactNode
  MarkPrice: ReactNode
  EntryPrice: ReactNode
  // LiqPrice: ReactNode
  Acitons: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowHeaderCells = (
    <>
      <TextLeftCell data-testid="position-cell">{Position}</TextLeftCell>
      <TextLeftCell data-testid="size-cell">{Size}</TextLeftCell>
      <TextLeftCell data-testid="netValue-cell">{NetValue}</TextLeftCell>
      {/* <TextLeftCell data-testid="collateral-cell">{Collateral}</TextLeftCell> */}
      <TextLeftCell data-testid="markPrice-cell">{MarkPrice}</TextLeftCell>
      <TextLeftCell data-testid="entryPrice-cell">{EntryPrice}</TextLeftCell>
      {/* <TextLeftCell data-testid="liqPrice-cell">{LiqPrice}</TextLeftCell> */}
      <TextRightCell data-testid="actions-cell">{Acitons}</TextRightCell>
    </>
  )
  const rowTrCells = (
    <>
      <TextLeftCell data-testid="position-cell">
        <Box p="16px 0" width="100%" height="100%">
          {Position}
        </Box>
      </TextLeftCell>
      <TextLeftCell data-testid="size-cell">
        <Box p="16px 0" width="100%" height="100%">
          {Size}
        </Box>
      </TextLeftCell>
      <TextLeftCell data-testid="netValue-cell">
        <Box p="16px 0" width="100%" height="100%">
          {NetValue}
        </Box>
      </TextLeftCell>
      {/* <TextLeftCell data-testid="collateral-cell">
        <Box p="16px 0" width="100%" height="100%" display="flex">
          {Collateral}
        </Box>
      </TextLeftCell> */}
      <TextLeftCell data-testid="markPrice-cell">
        <Box p="16px 0" width="100%" height="100%" display="flex">
          {MarkPrice}
        </Box>
      </TextLeftCell>
      <TextLeftCell data-testid="entryPrice-cell">
        <Box p="16px 0" width="100%" height="100%">
          {EntryPrice}
        </Box>
      </TextLeftCell>
      {/* <TextLeftCell data-testid="liqPrice-cell">
        <Box p="16px 0" width="100%" height="100%">
          {LiqPrice}
        </Box>
      </TextLeftCell> */}
      <TextRightCell data-testid="actions-cell">
        <Box display="flex" justifyContent="flex-end" p="16px 0" width="100%">
          {Acitons}
        </Box>
      </TextRightCell>
    </>
  )
  if (header) return <StyledHeaderRow data-testid="position-header-row">{rowHeaderCells}</StyledHeaderRow>
  return <StyledRow {...rest}>{rowTrCells}</StyledRow>
}

/* Header Row: top header row component for table */
// eslint-disable-next-line import/no-unused-modules
export function HeaderRow({ setShowLiqPrice, showLiqPirce }: { setShowLiqPrice: any; showLiqPirce: boolean }) {
  return (
    <PositionRow
      header={true}
      Position={<Trans>{TokenSortMethod.Position}</Trans>}
      Size={<Trans>{TokenSortMethod.Size}</Trans>}
      NetValue={<Trans>{TokenSortMethod.NetValue}</Trans>}
      // Collateral={<Trans>{TokenSortMethod.Collateral}</Trans>}
      MarkPrice={<Trans>{TokenSortMethod.MarkPrice}</Trans>}
      EntryPrice={
        <Trans>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setShowLiqPrice(!showLiqPirce)
            }}
          >
            {!showLiqPirce ? TokenSortMethod.EntryPrice : TokenSortMethod.LiqPrice}
            <StyledPriceChangeIcon style={{ marginLeft: '4px' }} />
          </div>
        </Trans>
      }
      // LiqPrice={<Trans>{TokenSortMethod.LiqPrice}</Trans>}
      Acitons={<Trans>{TokenSortMethod.Operation}</Trans>}
    />
  )
}
/* Loading State: row component with loading bubbles */
// eslint-disable-next-line import/no-unused-modules
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <PositionRow
      header={false}
      $loading
      Position={
        <Row>
          <IconLoadingBubble width="30px" height="30px" />
          <Box ml="8px" style={{ flex: 1 }}>
            <MediumLoadingBubble />
          </Box>
        </Row>
      }
      Size={<MediumLoadingBubble />}
      NetValue={<LoadingBubble />}
      // Collateral={<MediumLoadingBubble />}
      MarkPrice={<MediumLoadingBubble />}
      EntryPrice={<MediumLoadingBubble />}
      // LiqPrice={<MediumLoadingBubble />}
      Acitons={<MediumLoadingBubble />}
      {...props}
    />
  )
}

export interface LoadedPositionRowProps {
  positionListIndex: number
  positionListLength: number
  position: TradePositionProps
  showLiqPirce: boolean
  closePosition: (data: any) => void
  editPosition: (data: any) => void
}

/* Loaded State: row component with token information */
// eslint-disable-next-line import/no-unused-modules
export const LoadedRow = forwardRef((props: LoadedPositionRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { positionListIndex, positionListLength, position, closePosition, editPosition, showLiqPirce } = props
  const theme = useTheme()
  const token0 = useCurrency(position?.token0)
  const token1 = useCurrency(position?.token1)
  const { isToken0 } = useTradeSWAPState()

  const { currentSqrtRatioX96, traderFee, positonsymbol } = usePositonDetail(position)

  const isLong = position.long0 ? token1?.symbol : token0?.symbol
  const decimals = position.long0 ? token1?.decimals : token0?.decimals

  // const netValue = useMemo(() => {
  //   if (position && traderFee) {
  //     // const fee = formFeeWei(traderFee.positionFee)
  //     const size = BN(position.size.toString())
  //     if (position.hasProfit) {
  //       return fromWei(size.plus(position.delta.toString()).toFixed(), decimals).toFixed(2)
  //     } else {
  //       return fromWei(size.minus(position.delta.toString()).toFixed(), decimals).toFixed(2)
  //     }
  //   } else {
  //     return 0
  //   }
  // }, [decimals, position, traderFee])

  const initialCollateral = useMemo(() => {
    if (!position || !token0 || !token1 || !decimals) return
    return fromWei(position.collateral.toString(), decimals).toString()
  }, [decimals, position, token0, token1])

  const markPrice = useMemo(() => {
    if (!currentSqrtRatioX96) return
    const cur = fromSqrt96Wei(currentSqrtRatioX96.toString(), token0?.decimals, token1?.decimals)

    if (!isToken0) {
      return BN(1).div(cur).toFixed()
    }
    return cur.toFixed()
  }, [currentSqrtRatioX96, isToken0, token0?.decimals, token1?.decimals])

  const EntryPrice = useMemo(() => {
    if (!position) return
    const cur = fromSqrt96Wei(position.sqrtPriceX96.toString(), token0?.decimals, token1?.decimals)

    if (!isToken0) {
      return BN(1).div(cur).toFixed()
    }
    return cur.toFixed()
  }, [isToken0, position, token0?.decimals, token1?.decimals])

  const Openpnl = useMemo(() => {
    if (!markPrice || !EntryPrice) return null
    if (isToken0) {
      if (position.long0) {
        return BN(markPrice)
          .minus(EntryPrice)
          .dividedBy(EntryPrice)
          .multipliedBy(fromWei(position.size.toString(), decimals).toString())
          .toString()
      } else {
        return BN(EntryPrice)
          .minus(markPrice)
          .dividedBy(EntryPrice)
          .multipliedBy(fromWei(position.size.toString(), decimals).toString())
          .toString()
      }
    } else {
      if (position.long0) {
        return BN(1)
          .div(markPrice)
          .minus(BN(1).div(EntryPrice))
          .dividedBy(BN(1).div(EntryPrice))
          .multipliedBy(fromWei(position.size.toString(), decimals).toString())
          .toString()
      } else {
        return BN(1)
          .div(EntryPrice)
          .minus(BN(1).div(markPrice))
          .dividedBy(BN(1).div(EntryPrice))
          .multipliedBy(fromWei(position.size.toString(), decimals).toString())
          .toString()
      }
    }
  }, [EntryPrice, decimals, isToken0, markPrice, position.long0, position.size])
  const netValueFees = useMemo(() => {
    if (!traderFee || !position || !decimals || !initialCollateral || !Openpnl) return

    const positionFeeint = formFeeWei(traderFee.positionFee)

    const formsize = fromWei(position.size.toString(), decimals)
    const formcollateral = BN(initialCollateral)

    const pnl = Openpnl
    const positionFee = formsize.times(positionFeeint)

    const borrowFee = fromWei(position.fundingFee.toString(), decimals)
    const allFee = positionFee.times(2).plus(borrowFee.toString()).toFixed()

    // netvalue
    const currentCollateral = formcollateral.minus(positionFee).toFixed()

    const PnLAfterFees = BN(pnl).minus(allFee).toString()
    const PnLAfterFeesString = BN(pnl).minus(allFee).gt(0) ? `+${PnLAfterFees}` : PnLAfterFees
    const AfterHasProfiet = BN(PnLAfterFees).gt(BN(0))

    const currentvalue = formcollateral.plus(BN(pnl).minus(allFee)).toFixed()

    const rationPNLvalue = BN(pnl).minus(allFee).div(formcollateral).gt(0)
      ? `+${BN(pnl).minus(allFee).div(formcollateral).times(100).toFixed(2)}`
      : BN(pnl).minus(allFee).div(formcollateral).times(100).toFixed(2)

    return {
      borrowFee: borrowFee.toFixed(),
      closeSpread: '0',
      allFee,
      PnL: BN(pnl).gt(0) ? `+${pnl}` : pnl,
      Positionfees: positionFee.toFixed(),
      netValue: currentvalue,
      currentCollateral,
      PnLAfterFees: PnLAfterFeesString,
      AfterHasProfiet,
      ratioPnL: rationPNLvalue,
    }
  }, [Openpnl, decimals, initialCollateral, position, traderFee])

  const LiqPrice = useMemo(() => {
    if (!position) return
    const cur = fromSqrt96Wei(position.liqSqrtPriceX96.toString(), token0?.decimals, token1?.decimals)
    if (!isToken0) {
      return BN(1).div(cur).toFixed()
    }
    return cur.toFixed()
  }, [isToken0, position, token0?.decimals, token1?.decimals])

  const positionLong0 = useMemo(() => {
    if (position.long0) {
      if (isToken0) {
        return {
          long0: true,
          size: 'L',
        }
      } else {
        return {
          long0: false,
          size: 'S',
        }
      }
    } else {
      if (isToken0) {
        return {
          long0: false,
          size: 'S',
        }
      } else {
        return {
          long0: true,
          size: 'L',
        }
      }
    }
  }, [isToken0, position.long0])

  return (
    <div ref={ref} data-testid={`position-table-row-${position.account}`}>
      <PositionRow
        header={false}
        Position={
          <AutoColumn gap="xs">
            <ThemedText.TextPrimary fontWeight={700} fontSize={14}>
              {positonsymbol}
            </ThemedText.TextPrimary>
            <Row gap="xs">
              <ThemedText.TextPrimary fontWeight={400} fontSize={12}>
                {BN(position.size.toString()).div(position.collateral.toString()).toFixed(2)}x
              </ThemedText.TextPrimary>
              {positionLong0.long0 ? <LongIcon /> : <ShortIcon />}
            </Row>
          </AutoColumn>
        }
        Size={<PositionSize position={position} />}
        NetValue={
          <AutoColumn gap="xs">
            <PositionNetValue initialCollateral={initialCollateral} token={isLong} netValueFees={netValueFees} />
            <Row gap="xs" align="center">
              <ThemedText.BodySmall
                color={netValueFees?.AfterHasProfiet ? 'long' : 'tradeRed'}
                fontWeight={400}
                fontSize={12}
                style={{ whiteSpace: 'nowrap' }}
              >
                {/* {position?.hasProfit ? '+' : '-'} */}
                {countZeros(netValueFees?.PnLAfterFees)} ({netValueFees?.ratioPnL}%)
              </ThemedText.BodySmall>
            </Row>
          </AutoColumn>
        }
        // Collateral={
        //   <PositionCollateral initialCollateral={initialCollateral} netValueFees={netValueFees} token={isLong} />
        // }
        MarkPrice={<PositionMarkPrice currentSqrtRatioX96={markPrice} />}
        EntryPrice={
          <ThemedText.TextPrimary fontWeight={700} fontSize={12} display="flex">
            {!showLiqPirce ? (
              <>
                <StyledPositionEntryIcon color={theme.accentActive} opacity={1} style={{ marginRight: '4px' }} />{' '}
                {countZeros(EntryPrice)}
              </>
            ) : (
              <>
                {' '}
                <StyledPositionLiqIcon style={{ marginRight: '4px' }} /> {countZeros(LiqPrice)}
              </>
            )}
          </ThemedText.TextPrimary>
        }
        // LiqPrice={<ThemedText.TextPrimary fontWeight={700} fontSize={12} display="flex"></ThemedText.TextPrimary>}
        Acitons={
          <Row justify="flex-end" gap="xmd">
            <ThemedText.TextPrimary
              display="flex"
              alignItems="center"
              fontWeight={400}
              fontSize={12}
              style={{ cursor: 'pointer' }}
              onClick={() => editPosition(position)}
            >
              <StyledPositionEditIcon />
            </ThemedText.TextPrimary>
            <ThemedText.TextPrimary
              display="flex"
              alignItems="center"
              fontWeight={400}
              fontSize={12}
              style={{ cursor: 'pointer' }}
              onClick={() => closePosition(position)}
            >
              <StyledPositionCloseIcon />
            </ThemedText.TextPrimary>
          </Row>
        }
        first={positionListIndex === 0}
        last={positionListIndex === positionListLength - 1}
      />
    </div>
  )
})

LoadedRow.displayName = 'LoadedRow'
