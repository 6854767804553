import { Box } from '@mui/material'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { ReactComponent as TwitterIcon } from 'assets/imgs/airdrop/twitter.svg'
import { ReactComponent as NftIcon } from 'assets/imgs/nft.svg'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { shortenAddress } from 'utils'

const StyledAccountBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.sectionBorder};
`

const StyledTwitter = styled(Box)`
  display: inline-flex;
  padding: 4px 8px;
  align-items: flex-start;
  gap: 8px;
  cursor: pointer;
  border-radius: 4px;
  background: ${({ theme }) => theme.twitterBg};
`

export default function AccountInfo({ twitterUser }: any) {
  const handleTwitterPost = () => {
    // const tweetText = '@Roguex_io  post.'
    // window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`, '_blank')
    window.open(`https://twitter.com/${twitterUser?.twitter_username}`, '_blank')
  }

  return (
    <StyledAccountBox>
      <Box display="flex" alignItems="center" gap="8px">
        <NftIcon width={24} height={24} />
        <ConnectButton.Custom>
          {({ account }) => {
            return (
              <div>
                <ThemedText.TextSecondary fontSize={16} fontWeight={700}>
                  {shortenAddress(account?.address)}
                </ThemedText.TextSecondary>
              </div>
            )
          }}
        </ConnectButton.Custom>
      </Box>
      <StyledTwitter onClick={handleTwitterPost}>
        <TwitterIcon />
        <ThemedText.TextPrimary fontSize={12} fontWeight={600} color="white">
          @{twitterUser?.twitter_username}
        </ThemedText.TextPrimary>
      </StyledTwitter>
    </StyledAccountBox>
  )
}
