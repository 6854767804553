import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { ReactComponent as TradeIcon } from 'assets/imgs/airdrop/trade.svg'
import { TradeButton } from 'components/Button'
import Column from 'components/Column'
import Tips from 'components/Tips'
import styled, { useTheme } from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'
import { BN, fromWei } from 'utils/bn'
import { formatAmount } from 'utils/formatAmout'

import { useQueryTotalVolume } from './useQueryAirdrop'

const StyledInfoBox = styled(Box)`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  padding: 28px 0 16px 0;
  border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export default function TotalInfo({ ptsInfo }: any) {
  const theme = useTheme()

  const { data, isLoading } = useQueryTotalVolume()
  const tradingVolume = data?.userVolumes?.reduce((pre: any, curr: any) => {
    pre = pre.plus(fromWei(curr.totalValume))
    return pre
  }, BN(0))
  const reward_lp = data?.lpRecords?.reduce((pre: any, curr: any) => {
    pre = pre.plus(fromWei(curr.amountUSD))
    return pre
  }, BN(0))

  return (
    <StyledInfoBox>
      <Column align="flex-start" gap="4px">
        <Box display="flex" alignItems="center" gap="10px">
          <ThemedText.TextPrimary fontSize={16} fontWeight={800}>
            <Trans>Total</Trans>
          </ThemedText.TextPrimary>
          <Tips tipsStr="Total" />
        </Box>
        <Box display="flex" alignItems="flex-end" gap="10px">
          <ThemedText.TextPrimary
            fontSize={48}
            fontWeight={700}
            className={`${theme.darkMode ? 'active-gradient-text-dark' : 'active-gradient-text'}`}
          >
            {formatAmount(ptsInfo?.pts_total, 0, true)}
          </ThemedText.TextPrimary>
          <ThemedText.TextSecondary fontSize={16} fontWeight={600} mb="6px">
            <Trans>PTS.</Trans>
          </ThemedText.TextSecondary>
        </Box>
      </Column>
      <Column align="flex-start" gap="10px">
        <ThemedText.TextPrimary fontSize={12} fontWeight={600}>
          <Trans>Rank</Trans>
        </ThemedText.TextPrimary>
        <ThemedText.TextSecondary fontSize={24} fontWeight={600}>
          #{formatAmount(ptsInfo?.rank, 0, true)}
        </ThemedText.TextSecondary>
      </Column>
      <Column align="flex-start" gap="10px">
        <ThemedText.TextPrimary fontSize={12} fontWeight={600}>
          <Trans>Trading Volume</Trans>
        </ThemedText.TextPrimary>
        <Box display="flex" alignItems="center" gap="16px">
          <ThemedText.TextSecondary fontSize={24} fontWeight={600}>
            ${formatAmount(ptsInfo?.trade_vol, 2, true)}
          </ThemedText.TextSecondary>
          <TradeButton>
            <Box display="flex" alignItems="center" gap="8px">
              <Trans>Trade</Trans>
              <TradeIcon />
            </Box>
          </TradeButton>
        </Box>
      </Column>
      <Column align="flex-start" gap="10px">
        <ThemedText.TextPrimary fontSize={12} fontWeight={600}>
          <Trans>LP</Trans>
        </ThemedText.TextPrimary>
        <Box display="flex" alignItems="center" gap="16px">
          <ThemedText.TextSecondary fontSize={24} fontWeight={600}>
            ${formatAmount(ptsInfo?.lp_usd, 2, true)}
          </ThemedText.TextSecondary>
          <TradeButton>
            <Box display="flex" alignItems="center" gap="8px">
              <Trans>Trade</Trans>
              <TradeIcon />
            </Box>
          </TradeButton>
        </Box>
      </Column>
    </StyledInfoBox>
  )
}
